import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { ParkingClassifieds } from '~/models/classified/parking/types'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export class ClassifiedsFavoritesService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  getFavoriteClassifieds(
    category: number,
    sort: string
  ): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('get', '/api/classifieds/favorites/')
      .params({ category, sort, per_page: 1000 })
      .validate(body => body.data?.classifieds)
      .map(body => ({ ...body.data, sortOptions: body.data.sort_options }))
      .send()
  }

  addClassifiedToFavorites(classified: number): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('put', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }

  removeClassifiedFromFavorites(
    classified: number
  ): Promise<{ classifieds: number[] }> {
    return this.requestBuilder
      .request('delete', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }
}
