import { Context } from '@nuxt/types'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import onRequest from './axios/on-request'
import onResponse from './axios/on-response'
import axios, { AxiosRequestConfig } from 'axios'

const proxyHeadersIgnore = [
  'accept',
  'host',
  'x-forwarded-host',
  'x-forwarded-port',
  'x-forwarded-proto',
  'cf-ray',
  'cf-connecting-ip',
  'content-length',
  'content-md5',
  'content-type'
]

export default function(
  { route, req, app, res, $config }: Context,
  inject: any
) {
  const {
    public: { backendApiBaseUrl, backendApiBaseUrlFromClient }
  } = $config

  const serverBaseUrl = backendApiBaseUrl || 'http://localhost:8080'
  const clientBaseUrl = backendApiBaseUrlFromClient || '/'

  const axiosOptions: AxiosRequestConfig = {
    baseURL: process.server ? serverBaseUrl : clientBaseUrl,
    headers: {
      common: {}
    }
  }

  if (process.server) {
    // Don't accept brotli encoding because Node can't parse it
    axiosOptions.headers.common['accept-encoding'] = 'gzip, deflate'

    // Proxy SSR request headers
    if (req?.headers) {
      const reqHeaders = { ...req.headers }

      for (const h of proxyHeadersIgnore) {
        delete reqHeaders[h]
      }

      axiosOptions.headers.common = {
        ...reqHeaders,
        ...axiosOptions.headers.common
      }
    }
  }

  const axiosInstance = axios.create(axiosOptions)

  axiosInstance.interceptors.request.use(config =>
    onRequest(
      config,
      route,
      req,
      app.$requestContainer.resolve(CacheService),
      app.$requestContainer.resolve(CookiesService)
    )
  )

  axiosInstance.interceptors.response.use(axiosResponse =>
    onResponse(axiosResponse, app.$requestContainer.resolve(CacheService), res)
  )

  inject('axios', axiosInstance)
}
