import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dealers',
    path: '/dealers',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/index.vue' /* webpackChunkName: "pages-dealers-index.vue" */
        )
      )
  },
  {
    path: '/dealers/registration-info',
    name: '__managers_registration_info',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/managers/registration-info.vue' /* webpackChunkName: "pages-managers-registration-info.vue" */
        )
      )
  }
]
