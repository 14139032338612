import { interopDefault } from '~/router/utils'

export const ClassifiedViewPage = () =>
  interopDefault(
    import(
      '~/pages/car/classifieds/view/view.vue' /* webpackChunkName: "pages-car-classifieds-view-view" */
    )
  )

export const classifiedSlugOrIdPattern = ':slugOrId(\\d+\\-*.*)'

export default [
  {
    name: '__classifieds_view',
    path: `/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_plot',
    path: `/plot/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_plot_alt',
    path: `/plot/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_vtype',
    path: `/classifieds/:vtype/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_xyma',
    path: `/xyma/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_alt',
    path: `/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_alt2',
    path: `/classifieds/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_parts',
    path: `/parts/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_jobs',
    path: `/jobs/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_rentals',
    path: `/rentals/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  },
  {
    name: '__classifieds_view_wanted_parts',
    path: `/wanted-parts/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true,
      showOneTap: true
    }
  }
]
