







import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import Search from '~/components/car/classifieds/search/Search.vue'
import SearchShimmer from '~/components/car/classifieds/search/shimmers/SearchShimmer.vue'
import LegacySearchService from '~/services/search/LegacySearchService'
import RouteGuardService from '~/services/RouteGuardService'
import {
  computed,
  defineNuxtComponent,
  onBeforeUnmount,
  useStore
} from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS } from '~/store/modules/shared/user/state'
import { BackendMetaItem, MetaItem } from '~/models/head/types'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'

export default defineNuxtComponent({
  name: 'BaseSearchPage',
  components: { Search, SearchShimmer },
  middleware({ $dep, route, redirect }) {
    const routeIsMyClassifieds = route.name?.startsWith(
      '__classifieds_search_my_classifieds'
    )
    const userIsLoggedIn = $dep(RouteGuardService).userIsLoggedIn()

    if (routeIsMyClassifieds && !userIsLoggedIn) {
      redirect(`/login?gotonext=${route.path}`)
    }
  },
  async asyncData({ route, $error, $dep }) {
    const legacySearchService = $dep(LegacySearchService)
    await legacySearchService.createSearch({
      route,
      errorFn: $error,
      mapSearch: false
    })
  },
  setup() {
    const { state } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)
    const store = useStore()

    const searchId = computed(() => state.searchId)

    const unsubscribe = store.subscribeAction({
      after: action => {
        if (!process.client) {
          return
        }
        if (action.type === `${USER_NS}/loadUserSubscribable`) {
          window.location.reload()
        }
      }
    })

    onBeforeUnmount(() => {
      unsubscribe()
    })

    return {
      searchId
    }
  },
  head() {
    const dealerSiteService = this.$dep(DealerSiteService)

    const page = this.$store.state.classifieds.search.page

    const currentRouteFullPath = this.$store.getters[
      `${CLASSIFIED_SEARCH_NS}/getCurrentRouteFullPath`
    ]
    const consent = this.$store.state.page.consent

    const meta: MetaItem[] = page.metadata.map((metaItem: BackendMetaItem) => ({
      ...metaItem,
      hid: metaItem.name || metaItem.property
    }))

    if (!dealerSiteService.routeIsOfDealerSite(this.$router.currentRoute)) {
      meta.push({
        name: 'og:url',
        content: `https://www.car.gr${currentRouteFullPath}`
      })
    }

    if (consent?.name === 'over18') {
      meta.push(
        { name: 'rating', content: 'adult' },
        { name: 'rating', content: 'RTA-5042-1996-1400-1577-RTA' }
      )
    }

    return {
      title: page.title,
      meta
    }
  }
})
