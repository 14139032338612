import accountRoutes from '~/router/routes/car/account/index.ts'
import playgroundRoutes from '~/router/routes/shared/playground/index.ts'
import newFormRoutes from '~/router/routes/car/classifieds/new.ts'
import editRoutes from '~/router/routes/shared/classifieds/edit.ts'
import quickSearchRoutes from '~/router/routes/car/quick-search.ts'
import compareRoutes from '~/router/routes/shared/compare.ts'
import usersRoutes from '~/router/routes/car/users.ts'
import classifiedViewRoutes from '~/router/routes/car/classifieds/view.ts'
import dealersMapRoutes from '~/router/routes/car/dealers/map.ts'
import classifiedSearchRoutes from '~/router/routes/car/classifieds/search/index.ts'
import searchClassifiedByIdRoutes from '~/router/routes/car/search-classified-by-id.ts'
import parkingRoutes from '~/router/routes/car/parking.ts'
import pathDealerSiteRoutes from '~/router/routes/car/dealers/site/path.ts'
import dealerIntegrationRoutes from '~/router/routes/car/dealers/integration.ts'
import dealersRegionRoutes from '~/router/routes/car/dealers/index.ts'
import dealersPerRegionListRoutes from '~/router/routes/car/dealers/list.ts'
import informationRoutes from '~/router/routes/car/information.ts'
import sharedAdminRoutes from '~/router/routes/shared/admin/index.ts'
import adminRoutes from '~/router/routes/car/admin/index.ts'
import blacklistRoutes from '~/router/routes/car/blacklist.ts'
import indexRoutes from '~/router/routes/car/index.ts'
import listsRoutes from '~/router/routes/shared/lists.ts'
import advertising from '~/router/routes/car/advertising.ts'
import shareSettingsRoutes from '~/router/routes/shared/settings/index.ts'
import qnaRoutes from '~/router/routes/car/qna.ts'
import externalCertificatesRoutes from '~/router/routes/car/external-certificates.ts'
import notificationsRoutes from '~/router/routes/shared/notifications.ts'
import analyticsRoutes from '~/router/routes/shared/analytics.ts'
import feedsRoutes from '~/router/routes/shared/feeds.ts'
import filterRoutes from '~/router/routes/car/filters.ts'
import tradesRoutes from '~/router/routes/shared/trades.ts'
import auditsLogin from '~/router/routes/car/audits-login.ts'
import restoreTouchWithCodeRoutes from '~/router/routes/shared/classifieds/restore-touch-with-code.ts'
import oneClickTouchRoutes from '~/router/routes/shared/classifieds/one-click-touch.ts'
import landingPages from '~/router/routes/car/landing-pages.ts'
import changeEmail from '~/router/routes/shared/change-email.ts'
import loginRegister from '~/router/routes/car/login-register.ts'
import unsubscribeRoutes from '~/router/routes/shared/unsubscribe.ts'
import acceptAuditRequest from '~/router/routes/car/accept-audit-request.ts'
import deleteRatingForm from '~/router/routes/car/classifieds/delete-rating-form.ts'
import shortsRoutes from '~/router/routes/shared/shorts.ts'
import sharedLandingPages from '~/router/routes/shared/landing-pages.ts'
import vehicleServiceRoutes from '~/router/routes/car/vehicle-service/index.ts'
import piraeusLoanTerms from '~/router/routes/shared/piraeus-loan-terms.ts'
import xmlRoutes from '~/router/routes/car/xml.ts'
import saleRequestsRoutes from '~/router/routes/car/sale-requests.ts'
import alphabankRoutes from '~/router/routes/car/alpha-bank'
import marketplaceRoutes from '~/router/routes/car/marketplace.ts'
import aboutUsRoutes from '~/router/routes/car/about-us.ts'
import shortUrlRoutes from '~/router/routes/car/short-url.ts'
import contestTermsRoutes from '~/router/routes/car/contest-terms'

// When adding new routes remember: if they start with /user/ add them on top to avoid override by userProfileRoutes

export default [
  ...accountRoutes,
  ...playgroundRoutes,
  ...newFormRoutes,
  ...editRoutes,
  ...quickSearchRoutes,
  ...compareRoutes,
  ...usersRoutes,
  ...classifiedViewRoutes,
  ...dealersMapRoutes,
  ...classifiedSearchRoutes,
  ...searchClassifiedByIdRoutes,
  ...parkingRoutes,
  ...pathDealerSiteRoutes,
  ...dealerIntegrationRoutes,
  ...dealersRegionRoutes,
  ...dealersPerRegionListRoutes,
  ...informationRoutes,
  ...sharedAdminRoutes,
  ...adminRoutes,
  ...blacklistRoutes,
  ...indexRoutes,
  ...listsRoutes,
  ...advertising,
  ...shareSettingsRoutes,
  ...qnaRoutes,
  ...externalCertificatesRoutes,
  ...notificationsRoutes,
  ...analyticsRoutes,
  ...feedsRoutes,
  ...filterRoutes,
  ...tradesRoutes,
  ...auditsLogin,
  ...restoreTouchWithCodeRoutes,
  ...oneClickTouchRoutes,
  ...landingPages,
  ...changeEmail,
  ...loginRegister,
  ...unsubscribeRoutes,
  ...acceptAuditRequest,
  ...deleteRatingForm,
  ...shortsRoutes,
  ...sharedLandingPages,
  ...vehicleServiceRoutes,
  ...piraeusLoanTerms,
  ...alphabankRoutes,
  ...xmlRoutes,
  ...saleRequestsRoutes,
  ...marketplaceRoutes,
  ...aboutUsRoutes,
  ...shortUrlRoutes,
  ...contestTermsRoutes
]
