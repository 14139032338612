



































import { mapGetters } from 'vuex'
import { EnginePowerTypeEnum } from '~/models/search/types'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import RangeSearchHandler from '~/components/car/classifieds/search/facets/handlers/RangeSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LegacySearchService from '~/services/search/LegacySearchService'
import CRadioButtonGroup from '~/components/shared/configurable/button/CRadioButtonGroup.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CRadioButtonGroup,
    RangeSearchHandler
  },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedType: EnginePowerTypeEnum.ENGINE_SIZE
    }
  },

  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName'
    }),
    ...mapDeps({ legacySearchService: LegacySearchService }),
    engineSizeFacet() {
      return this.getFacetByName('engine_size')
    },
    enginePowerFacet() {
      return this.getFacetByName('engine_power')
    },
    enginePowerButtonVariant() {
      return this.selectedType === EnginePowerTypeEnum.ENGINE_POWER
        ? 'primary'
        : 'light'
    },
    engineSizeButtonVariant() {
      return this.selectedType === EnginePowerTypeEnum.ENGINE_SIZE
        ? 'primary'
        : 'light'
    },
    enginePowerTypeEnum() {
      return EnginePowerTypeEnum
    },
    selectedHandler(): object {
      return this.selectedType === EnginePowerTypeEnum.ENGINE_SIZE &&
        this.engineSizeFacet
        ? this.engineSizeFacet
        : this.enginePowerFacet
    },
    enginePowerOptions() {
      return [
        {
          label: this.enginePowerFacet.humanName,
          value: EnginePowerTypeEnum.ENGINE_POWER
        },
        {
          label: this.engineSizeFacet.humanName,
          value: EnginePowerTypeEnum.ENGINE_SIZE
        }
      ]
    }
  },
  methods: {
    getFacetBindings(handler) {
      return this.legacySearchService.getFacetComponentBindings(handler)
    },
    onClear() {
      const engineSizeParamName = this.engineSizeFacet.name
      const enginePowerParamName = this.enginePowerFacet.name
      this.emitParams([
        {
          name: `${engineSizeParamName}-from`,
          value: null
        },
        {
          name: `${engineSizeParamName}-to`,
          value: null
        },
        {
          name: `${enginePowerParamName}-from`,
          value: null
        },
        {
          name: `${enginePowerParamName}-to`,
          value: null
        }
      ])
    },
    onTypeSelect(type: EnginePowerTypeEnum) {
      this.selectedType = type
      if (type === EnginePowerTypeEnum.ENGINE_POWER) {
        this.$refs.engineSizeHandler.onClear()
      } else {
        this.$refs.enginePowerHandler.onClear()
      }
    }
  }
})
