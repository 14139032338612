import { getRouteMetaItem } from '~/utils/router'
import { USER_NS } from '~/store/modules/shared/user/state'
import Clarity from '@microsoft/clarity'

export default ({ store, route, $config }: any) => {
  const {
    public: { environment }
  } = $config

  const isIframeRoute = Boolean(getRouteMetaItem(route, 'isIframe'))
  const isProductionEnv = environment === 'production'

  const isClarityEnabled = isProductionEnv && !isIframeRoute

  if (!isClarityEnabled) {
    return
  }

  Clarity.init('jjn6e1b6fw')

  // Some ad-blocker or other extension blocked the clarity script
  if (!window.clarity || typeof window.clarity !== 'function') {
    return
  }

  Clarity.setTag('usertype', store.state.user.type)

  const userIsAnon = store.getters[`${USER_NS}/isAnon`]
  if (!userIsAnon) {
    const userId = store.state.user.id.toString()

    Clarity.identify(userId)
  }

  let cmpLoadRetryCount = 0

  const cmpPingInterval = setInterval(() => {
    ;(window.__tcfapi || function() {})(
      'ping',
      2,
      ({ cmpLoaded }: { cmpLoaded: boolean }) => {
        if (!cmpLoaded) {
          return
        }

        // CMP is loaded, add event listener for consent changes
        window.__tcfapi('addEventListener', 2, (tcData: any) => {
          if (
            tcData.eventStatus === 'tcloaded' ||
            tcData.eventStatus === 'useractioncomplete'
          ) {
            // Purpose 1: "Store and/or access information on a device"
            const trackingPurposeId = 1

            const legitimateInterestAllowed = !!tcData.purpose
              .legitimateInterests[trackingPurposeId]

            const userHasConsented =
              !!tcData.purpose.consents[trackingPurposeId] ||
              legitimateInterestAllowed

            // Pass consent information to Microsoft Clarity
            Clarity.consent(userHasConsented)
          }
        })

        // Stop the interval
        clearInterval(cmpPingInterval)
      }
    )

    cmpLoadRetryCount += 1

    if (cmpLoadRetryCount >= 5) {
      clearInterval(cmpPingInterval)
    }
  }, 2000)
}
