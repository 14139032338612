import { inject } from 'tsyringe'
import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { logError } from '~/decorators/error/logger'
import {
  defaultRecordEventParams,
  defaultRecordPageViewParams
} from '~/constants/telemetry/analytics'
import {
  DebouncedAnalyticsPageViewFunction,
  MeasurementId,
  RecordPageViewParams,
  RecordEventParams
} from '~/models/analytics'
import LoggerService from '~/services/LoggerService'
import { FacebookPixelService } from '~/services/telemetry/FacebookPixelService'
import { debounce } from '~/utils/function'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { StoreWithRootState } from '~/store/types'
import {
  configToken,
  storeToken
} from '~/constants/dependency-injection/tokens'

@ClientOnly
@containerScoped()
export default class AnalyticsService {
  constructor(
    @inject(LoggerService) public logger: LoggerService,
    @inject(FacebookPixelService) private fbPixelService: FacebookPixelService,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(configToken) private config: any
  ) {}

  /**
   * Initialize gtag and fb pixel configs.
   */
  @logError()
  initialize() {
    const ga4MeasurementId = this.getOwnGa4MeasurementIdFromEnv()
    if (!ga4MeasurementId) {
      throw new TypeError(
        'Env var GOOGLE_ANALYTICS_V4_MEASUREMENT_ID is required'
      )
    }

    window.dataLayer = window.dataLayer || []

    const originalPush = window.dataLayer.push

    // manipulating the data layer here in order to prevent the history change event from running
    // we don't have it enabled but dealers/sellers might, and without this fix they were receiving all client-side
    // navigation page views after their tag was loaded to config (e.g classified view)
    // @ts-ignore
    window.dataLayer.push = function(event) {
      // @ts-ignore
      if (event?.event === 'gtm.historyChange-v2') {
        // Don't send the event
        return
      }

      // @ts-ignore
      originalPush.apply(this, arguments)
    }

    window.gtag = function gtag() {
      window.dataLayer.push(arguments)
    }

    window.gtag('js', new Date())
    ga4MeasurementId && this.createGtagConfig(ga4MeasurementId)

    const isProduction = process.env.NODE_ENV === 'production'

    if (isProduction && !this.dealerSiteService.routeIsOfDealerSite()) {
      // This is used for conversions and Google Ads tracking
      const gtag = this.getGtag()
      gtag('config', 'AW-1038184476', {
        send_page_view: false
      })
    }

    this.fbPixelService.initialize()
  }

  createGtagConfig(measurementId: string) {
    const ownId = this.getOwnGa4MeasurementIdFromEnv()
    const gtag = this.getGtag()
    gtag('config', measurementId, {
      send_page_view: false,
      cookie_expires:
        !this.dealerSiteService.routeIsOfDealerSite() && measurementId !== ownId
          ? 60 * 30
          : undefined
    })
  }

  /**
   * Returns google global site tag library instance.
   * @private
   */
  // eslint-disable-next-line no-undef
  private getGtag(): Gtag.Gtag {
    return window.gtag || window.parent.gtag || function() {}
  }

  /**
   *
   * @param measurementId Google Analytics V4 Measurement id
   * @param url
   * @param includeFbPixel Send page view to google pixel too
   */
  @logError()
  recordPageView(
    measurementId:
      | MeasurementId
      | MeasurementId[]
      | null
      | undefined = this.getDefaultMeasurementIds(),
    {
      url = defaultRecordPageViewParams.url,
      includeFacebookPixel = defaultRecordPageViewParams.includeFacebookPixel
    }: RecordPageViewParams = defaultRecordPageViewParams
  ) {
    if (!measurementId) {
      measurementId = this.getDefaultMeasurementIds()
    }
    const title = document.title
    const failsafeUrl = url || window.location.pathname + window.location.search

    if (Array.isArray(measurementId)) {
      for (const id of measurementId) {
        this.sendGtagPageView(id, failsafeUrl, title)
      }
    } else if (measurementId) {
      this.sendGtagPageView(measurementId, failsafeUrl, title)
    }

    includeFacebookPixel && this.fbPixelService.sendEvent('PageView')
  }

  createDebouncedPageViewRecordFunction(
    ms: number
  ): DebouncedAnalyticsPageViewFunction {
    return debounce<DebouncedAnalyticsPageViewFunction>(
      (measurementId, params) => {
        this.recordPageView(measurementId, params)
      },
      ms
    ) as DebouncedAnalyticsPageViewFunction
  }

  @logError()
  recordEvent({
    namespace = defaultRecordEventParams.namespace,
    action = defaultRecordEventParams.action,
    label = defaultRecordEventParams.label,
    customData = defaultRecordEventParams.customData,
    measurementId
  }: RecordEventParams = defaultRecordEventParams) {
    let ids: MeasurementId[]
    if (measurementId) {
      ids = Array.isArray(measurementId) ? measurementId : [measurementId]
    } else {
      ids = this.getDefaultMeasurementIds()
    }

    const gtag = this.getGtag()
    for (const id of ids) {
      gtag('event', namespace, {
        event_category: action,
        event_label: label,
        user_type: this.store.state.user?.type,
        send_to: id,
        ...customData
      })
    }
  }

  /**
   * @param measurementId V4 Measurement ID or UA Tracker Name.
   * @param url
   * @param title
   */
  @logError()
  private sendGtagPageView(measurementId: string, url: string, title: string) {
    const gtag = this.getGtag()
    gtag('event', 'page_view', {
      page_title: title,
      page_location: location.origin + (url[0] === '/' ? url : '/' + url),
      page_path: url,
      send_to: measurementId
    })
  }

  getOwnGa4MeasurementIdFromEnv(): MeasurementId | undefined {
    const {
      public: { googleAnalyticsV4MeasurementId }
    } = this.config

    return googleAnalyticsV4MeasurementId
  }

  getDefaultMeasurementIds(): MeasurementId[] {
    const ids = []
    const ownGa4MeasurementId = this.getOwnGa4MeasurementIdFromEnv()
    ownGa4MeasurementId && ids.push(ownGa4MeasurementId)
    return ids
  }
}
