import { interopDefault } from '~/router/utils'

export const adminSaleRequestsRoutes = [
  {
    name: '__admin_sale_requests',
    path: '/admin/sale-requests',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/sale-requests/index.vue' /* webpackChunkName: "pages-admin-sale-requests-index" */
        )
      ),
    redirect: { name: '__admin_sale_requests_overview' },
    children: [
      {
        name: '__admin_sale_requests_overview',
        path: '/admin/sale-requests/overview',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/sale-requests/overview.vue' /* webpackChunkName: "pages-admin-sale-requests-overview" */
            )
          )
      },
      {
        name: '__admin_sale_requests_managers',
        path: '/admin/sale-requests/managers',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/sale-requests/managers.vue' /* webpackChunkName: "pages-admin-sale-requests-managers" */
            )
          )
      },
      {
        name: '__admin_sale_requests_managers_manager_items',
        path: '/admin/sale-requests/managers/:managerId/items',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/sale-requests/managers-manager-items.vue' /* webpackChunkName: "pages-admin-sale-requests-managers-manager-items" */
            )
          )
      },
      {
        name: '__admin_sale_requests_reports',
        path: '/admin/sale-requests/reports',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/sale-requests/reports.vue' /* webpackChunkName: "pages-admin-sale-requests-reports" */
            )
          )
      }
    ]
  }
]
