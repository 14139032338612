





















import { computed, defineComponent } from '~/utils/nuxt3-migration'
import { ciCart } from '~/icons/source/solid/cart'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'

export default defineComponent({
  setup() {
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)

    const cartItemsCount = computed(() =>
      userGetters('marketplaceCartItemsCount')
    )

    return {
      ciCart,
      cartItemsCount
    }
  }
})
