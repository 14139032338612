

















import { mapGetters, mapState } from 'vuex'
import { paramsValuesAsArrays, preprocessParams } from '~/utils/http'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import LegacySearchService from '~/services/search/LegacySearchService'
import Pagination from '~/components/car/classifieds/search/results/Pagination.vue'
import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'

import NewClassifiedButton from '~/components/car/classifieds/search/results/NewClassifiedButton.vue'
import ChangeSearchButton from '~/components/car/classifieds/search/results/ChangeSearchButton.vue'

export default defineComponent({
  components: {
    Pagination,
    ShowOnMapButton: defineVue3AsyncComponent(() =>
      import('~/components/car/classifieds/search/results/ShowOnMapButton.vue')
    ),
    NewClassifiedButton,
    ChangeSearchButton
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      showInPlotUrl: state => state.showInPlotUrl,
      slots: state => state.config.slots,
      perPage: state => state.perPage,
      total: state => state.pagination.total,
      rootParams: state => state.config.rootParams,
      params: state => state.params,
      loadingResults: state => state.loading.results,
      settings: state => state.config.settings,
      mapSearchUrl: state => state.mapSearchUrl
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      numOfPages: 'getNumOfPages',
      totalRows: 'getTotalRows',
      currentPage: 'getResultsPaginationPage',
      showSellers: 'showSellers',
      inVehiclesSearch: 'inVehiclesSearch',
      inXymaSearch: 'inXymaSearch',
      inPartsSearch: 'inPartsSearch',
      inPlotSearch: 'inPlotSearch'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    showNewClassifiedButton(): boolean {
      return (
        this.inVehiclesSearch ||
        this.inXymaSearch ||
        this.inPartsSearch ||
        this.inPlotSearch
      )
    },
    showChangeSearchButton(): boolean {
      return this.inXymaSearch
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && this.currentPage * this.perPage < this.total) {
        this.prefetchNextPage()
      }
    },
    prefetchNextPage() {
      const paramsToPrepare = {
        ...this.rootParams,
        ...this.params,
        ...{
          pg: this.currentPage + 1
        }
      }
      const params = paramsValuesAsArrays(preprocessParams(paramsToPrepare))
      const legacySearchService = this.$dep(LegacySearchService)
      if (this.showSellers) {
        return legacySearchService.getSellers(params)
      }
      paramsToPrepare['per-page'] = [this.perPage]
      return legacySearchService.getResults(params)
    }
  }
})
