


















































































import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import ForMakeModelSearchHandlerMixin from '~/components/car/classifieds/search/facets/handlers/MakeModel/ForMakeModelSearchHandlerMixin.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CCustomSelect
  },
  mixins: [ForMakeModelSearchHandlerMixin]
})
