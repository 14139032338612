import GoogleAdsService from '~/services/ads/GoogleAdsService'
import { CONSENT_COOKIE_NAME } from '~/constants/cookies'

export default ({ $dep, $cookies }: any) => {
  const googleAdsService = $dep(GoogleAdsService)

  googleAdsService.initialize()

  const choiceMade = $cookies.get(CONSENT_COOKIE_NAME)

  if (!choiceMade) {
    googleAdsService.disableInitialLoad()
  }
}
