import { AxiosError } from 'axios'
import {
  qnaSearch,
  fetchModelsByMake,
  fetchAllCategories,
  fetchMakesByCategoryId,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createComment,
  createQuestionReaction,
  addCommentReply,
  addCommentReaction,
  updateComment,
  deleteComment,
  changeCommentVisibility,
  fetchMoreComments,
  fetchQuestionDataById
} from './actions-types'
import { QnaState } from './state'
import { ActionTreeWithRootState } from '~/store/types'
import QnaService from '~/services/qna/QnaService'
import MakeModelService from '~/services/MakeModelService'
import {
  ADD_COMMENT,
  ADD_QUESTION_REACTION,
  DELETE_QUESTION,
  SET_CATEGORY_DATA,
  SET_LOADING_STATE,
  SET_MAKES_WITH_MODELS_DATA,
  SET_MAKE_DATA,
  SET_QUESTION_DATA,
  UPDATE_QUESTION,
  SET_MORE_COMMENT_REPLIES,
  SET_COMMENT_VISIBILITY,
  DELETE_COMMENT,
  SET_COMMENT_REACTION,
  ADD_COMMENT_REPLY,
  SET_FULL_QUESTION_DATA,
  UPDATE_COMMENT
} from './mutation-types'
import { removeKeysWithNoValues, toCamelCase } from '~/utils/object'
import { QnaComment, QnaFormData, QnaQuestion } from '~/models/qna/types'
import { ReactionEnum } from '~/models/classified-list/types'
import { HttpStatus } from '~/constants/http'

export default {
  async [qnaSearch]({ commit }, params: object) {
    const qnaService = this.$dep(QnaService)
    commit(SET_LOADING_STATE, { loadingVar: 'questions', status: true })
    try {
      const { pagination, rows: questions } = await qnaService.questionSearch(
        removeKeysWithNoValues(toCamelCase(params)) as {}
      )
      commit(SET_QUESTION_DATA, {
        questions,
        pagination
      })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'questions', status: false })
    }
  },
  async [fetchAllCategories]({ commit, state }) {
    const qnaService = this.$dep(QnaService)
    if (state.categories.length) {
      return
    }
    commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: true })
    try {
      const data = await qnaService.fetchAllCategories()
      commit(SET_CATEGORY_DATA, data)
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: false })
    }
  },

  async [fetchModelsByMake](
    { commit },
    { categoryId, makeIds }: { categoryId: number; makeIds: number | number[] }
  ) {
    const qnaService = this.$dep(QnaService)
    const makeModelService = this.$dep(MakeModelService)
    commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: true })
    try {
      const makesWithModels = await makeModelService.fetchModelsByMake(
        categoryId,
        makeIds
      )
      commit(SET_MAKES_WITH_MODELS_DATA, { categoryId, makesWithModels })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: false })
    }
  },
  async [fetchMakesByCategoryId]({ commit, state }, categoryId) {
    const qnaService = this.$dep(QnaService)
    const makeModelService = this.$dep(MakeModelService)
    const makesData = state.makeAndModelsByCategory[categoryId]

    if (makesData) {
      commit(SET_MAKE_DATA, { categoryId, makes: makesData })
      return
    }

    commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: true })
    try {
      const makes = await makeModelService.fetchMakesByCategoryId(categoryId)
      commit(SET_MAKE_DATA, { categoryId, makes })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'makesAndModels', status: false })
    }
  },
  async [createQuestion]({ commit }, formData: QnaFormData) {
    const qnaService = this.$dep(QnaService)
    commit(SET_LOADING_STATE, { loadingVar: 'questions', status: true })
    try {
      const question = await qnaService.createNewQuestion(formData)
      // @ts-ignore
      this.$snackbar.success(
        // @ts-ignore
        this.$i18n.t('the question was created successfully')
      )
      commit('ADD_QUESTION', question)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'questions', status: false })
    }
  },
  async [updateQuestion]({ commit }, formData: QnaFormData) {
    const qnaService = this.$dep(QnaService)
    commit(SET_LOADING_STATE, { loadingVar: 'questions', status: true })
    try {
      const question = await qnaService.updateQuestion(formData)
      // @ts-ignore
      this.$snackbar.success(
        // @ts-ignore
        this.$i18n.t('the question was updated successfully')
      )
      commit(UPDATE_QUESTION, question)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'questions', status: false })
    }
  },
  async [deleteQuestion](
    { commit },
    {
      question,
      userId,
      isAdmin
    }: { question: QnaQuestion; userId: number; isAdmin: boolean }
  ) {
    const qnaService = this.$dep(QnaService)

    try {
      const questionData = await qnaService.changeQuestionVisibility(
        question.id,
        question.isDeleted
      )
      let visibilityMsg = questionData.isDeleted
        ? // @ts-ignore
          this.$i18n.t('the question is hidden')
        : // @ts-ignore
          this.$i18n.t('the question is visible')

      if (userId === questionData?.user?.id && !isAdmin) {
        // @ts-ignore
        visibilityMsg = this.$i18n.t('the question deleted successfully')
      }
      commit(DELETE_QUESTION, { questionId: question.id, isAdmin })
      this.$snackbar.success(visibilityMsg)
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [createComment](
    { commit },
    {
      questionId,
      answer,
      isFirstComment
    }: { questionId: string; answer: string; isFirstComment: boolean }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      const comment = await qnaService.createQuestionComment(questionId, answer)
      this.$snackbar.success(
        // @ts-ignore
        this.$i18n.t('the answer was created successfully')
      )
      commit(ADD_COMMENT, { questionId, comment, isFirstComment })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [createQuestionReaction](
    { commit },
    {
      questionId,
      reaction
    }: {
      questionId: string
      reaction: ReactionEnum
    }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      const reactionStatus = await qnaService.createQuestionReaction(
        questionId,
        reaction
      )
      commit(ADD_QUESTION_REACTION, { questionId, reaction, reactionStatus })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },

  async [fetchMoreComments]({ commit }, commentId: number) {
    const qnaService = this.$dep(QnaService)
    try {
      const replies: QnaComment = await qnaService.fetchCommentById(commentId)

      commit(SET_MORE_COMMENT_REPLIES, { commentId, replies })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [changeCommentVisibility](
    { commit },
    {
      commentId,
      isHidden,
      isFirstComment,
      questionId
    }: {
      commentId: number
      isHidden: boolean
      isFirstComment: boolean
      questionId: number
    }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      let updatedComment = null
      if (isHidden) {
        updatedComment = await qnaService.makeCommentVisible(commentId)
        // @ts-ignore
        this.$snackbar.success(this.$i18n.t('the answer is visible'))
      } else {
        updatedComment = await qnaService.makeCommentInvisible(commentId)
        // @ts-ignore
        this.$snackbar.success(this.$i18n.t('the answer is hidden'))
      }
      commit(SET_COMMENT_VISIBILITY, {
        commentId,
        visibilityStatus: updatedComment.isDeleted,
        isFirstComment,
        questionId
      })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },

  async [updateComment](
    { commit },
    {
      comment,
      commentId,
      isFirstComment,
      questionId
    }: {
      comment: string
      commentId: number
      isFirstComment: boolean
      questionId: number
    }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      const updatedComment = await qnaService.updateComment(comment, commentId)
      commit(UPDATE_COMMENT, {
        comment: updatedComment,
        questionId,
        isFirstComment
      })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },

  async [deleteComment](
    { commit },
    {
      comment,
      isFirstComment,
      questionId
    }: { comment: QnaComment; isFirstComment: boolean; questionId: number }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      await qnaService.deleteComment(comment.id)
      commit(DELETE_COMMENT, { comment, isFirstComment, questionId })

      this.$snackbar.success(
        // @ts-ignore
        this.$i18n.t('the comment has been deleted successfully')
      )
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [addCommentReaction](
    { commit },
    {
      commentId,
      reaction,
      firstComment
    }: {
      commentId: number
      reaction: ReactionEnum
      firstComment: QnaComment
    }
  ) {
    const qnaService = this.$dep(QnaService)

    try {
      await qnaService.createCommentReaction(commentId, reaction)
      commit(SET_COMMENT_REACTION, { commentId, reaction, firstComment })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [addCommentReply](
    { commit },
    {
      questionId,
      commentId,
      comment,
      isFirstComment
    }: {
      questionId: string
      commentId: number
      comment: string
      isFirstComment: boolean
    }
  ) {
    const qnaService = this.$dep(QnaService)
    try {
      const newComment: QnaComment = await qnaService.createCommentReply(
        questionId,
        commentId,
        comment
      )

      commit(ADD_COMMENT_REPLY, { newComment, questionId, isFirstComment })
    } catch (error) {
      qnaService.handleError(error as AxiosError)
    }
  },
  async [fetchQuestionDataById]({ commit }, questionId: string) {
    commit(SET_LOADING_STATE, { loadingVar: 'fullQuestion', status: true })

    const qnaService = this.$dep(QnaService)
    try {
      const { comments, question } = await qnaService.fetchQuestionDataById(
        questionId
      )
      commit(SET_FULL_QUESTION_DATA, { comments, question })
    } catch (error) {
      if ((error as AxiosError)?.response?.status === HttpStatus.GONE) {
        commit(SET_FULL_QUESTION_DATA, {
          comments: [],
          question: { isDeleted: true }
        })
      }

      qnaService.handleError(error as AxiosError)
    } finally {
      commit(SET_LOADING_STATE, { loadingVar: 'fullQuestion', status: false })
    }
  }
} as ActionTreeWithRootState<QnaState>
