import { inject } from 'tsyringe'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import {
  configToken,
  storeToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { LoginResult } from '~/models/login-register/types'
import { VueRouter } from '~/utils/nuxt3-migration'
import { FIVE_MINUTES_S } from '~/constants/duration'
import CookiesService from '~/services/CookiesService'
import { inBrowser } from '~/utils/env'
import LoggerService from '~/services/LoggerService'
import { USER_NS } from '~/store/modules/shared/user/state'
import { StoreWithRootState } from '~/store/types'

export const GOOGLE_SIGNIN_EVENT_ID = 'google_signin'
export const GOOGLE_STATE_TOKEN_COOKIE_NAME = 'gstkn'

@containerScoped()
export default class GoogleLoginService {
  private authWindow: any = null
  private scriptLoaded = false
  private accountInitialized = false
  private loader: any = null

  constructor(
    @inject(CookiesService) private cookies: CookiesService,
    @inject(LoggerService) private logger: LoggerService,
    @inject(configToken) private config: any,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(storeToken) private store: StoreWithRootState
  ) {}

  @clientOnly
  public async getGoogle(): Promise<any> {
    if (!this.scriptLoaded) {
      if (!this.loader) {
        this.initScript()
      }
      await this.loader
    }

    return inBrowser() ? window.FB : {}
  }

  @clientOnly
  initScript() {
    const loader = () => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script')
        script.id = 'google-gsi'
        script.type = 'text/javascript'
        script.async = true
        script.defer = true
        script.setAttribute('src', 'https://accounts.google.com/gsi/client')
        script.onerror = err => reject(err)
        script.onload = () => resolve()
        document.head.appendChild(script)
      })
    }
    this.loader = loader().catch(e => this.logger.captureError(e))
  }

  @clientOnly
  async showOneTap(): Promise<void> {
    const google = await this.getGoogle()

    const {
      public: { googleOauthClientId }
    } = this.config

    if (google?.accounts?.id) {
      if (!this.accountInitialized) {
        google.accounts.id.initialize({
          client_id: googleOauthClientId,
          callback: 'handleOneTapCredentialResponse'
        })
        this.accountInitialized = true
      }

      google.accounts.id.prompt()
    }
  }

  @clientOnly
  public openPopup(stateToken: string) {
    this.setStateCookie(stateToken)
    const url = this.getUrl(stateToken)
    const h = 600
    const w = 500

    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

    this.authWindow = window.open(
      '',
      'ConnectWithOAuth',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, top=${y}, left=${x} width=${w}, height=${h}`
    )
    this.authWindow.location.href = url
    this.authWindow.focus()
  }

  public setStateCookie(stateToken: string) {
    this.cookies.set(GOOGLE_STATE_TOKEN_COOKIE_NAME, stateToken, {
      maxAge: FIVE_MINUTES_S
    })
  }

  public validateStateCookie(returnedStateToken: string): Boolean {
    const cookieStateToken = this.cookies.get(GOOGLE_STATE_TOKEN_COOKIE_NAME)
    this.cookies.delete(GOOGLE_STATE_TOKEN_COOKIE_NAME)

    return cookieStateToken === returnedStateToken
  }

  @clientOnly
  public addMessageListener(callback: any) {
    window.addEventListener('message', callback)
  }

  @clientOnly
  public removeMessageListener(callback: any) {
    window.removeEventListener('message', callback)
  }

  @clientOnly
  public getAuthWindow(): any {
    return this.authWindow
  }

  @clientOnly
  public backendLogin(
    code?: string,
    idToken?: string
  ): Promise<LoginResult | null> {
    let redirectTo
    const currentQuery = this.router?.currentRoute?.query
    if (currentQuery?.gotonext || currentQuery?.redirectto) {
      redirectTo = currentQuery?.gotonext || currentQuery?.redirectto
    }
    let refcode
    if (this.store.getters[`${USER_NS}/isSingleOrAnon`]) {
      refcode = this.store.getters[`${USER_NS}/referenceCode`]
    } else if (this.router?.currentRoute?.query?.refcode) {
      refcode = this.router.currentRoute.query.refcode
    }

    return this.requestBuilder
      .request('post', '/api/login/google/')
      .data({
        id_token: idToken,
        code,
        redirect_to: redirectTo,
        refcode
      })
      .send()
  }

  @clientOnly
  public getUrl(stateToken: string): string {
    const href = this.router.resolve({
      name: '__google_signin_redirect'
    }).href
    let redirectUrl = window.location.origin + href
    if (redirectUrl.slice(-1) !== '/') {
      redirectUrl += '/'
    }

    const {
      public: { googleOauthClientId }
    } = this.config

    // Authorization endpoint should be retrieved dynamically
    // by https://accounts.google.com/.well-known/openid-configuration
    return `https://accounts.google.com/o/oauth2/v2/auth?
             scope=openid%20email%20profile&
             include_granted_scopes=true&
             response_type=code&
             state=${stateToken}&
             redirect_uri=${redirectUrl}&
             client_id=${googleOauthClientId}
            `.replace(/\s/g, '')
  }
}
