import Vue from 'vue'
import Router from 'vue-router'
import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import { container } from 'tsyringe'
import { scrollBehavior, setScrollRestoration } from './utils'
import SubdomainService from '../services/SubdomainService'
import routes from '~/router/routes'

Vue.use(Router)

setScrollRestoration()

const router = routes =>
  new Router({
    mode: 'history',
    scrollBehavior,
    routes
  })

/**
 *
 * @param serverSideCtx {Context | undefined} This is undefined on the client side.
 * @returns {VueRouter}
 */
export function createRouter(serverSideCtx) {
  const subdomainService = container.resolve(SubdomainService)
  subdomainService.setContext(serverSideCtx)

  if (subdomainService.shouldCreateSubdomainRouter()) {
    const subdomainRoutes = subdomainService.getRoutes() // this needs to be here before the handleSubdomainRouterCreationCookie
    subdomainService.handleSubdomainRouterCreationCookie()
    return router(subdomainRoutes)
  }

  // Pre-emptive deletion to guarantee that no cookie is being left over.
  subdomainService.deleteSubdomainRouterCreationCookieFromClient()

  return router(routes)
}
