import { CIcon } from '~/icons/types'

export const ciLocationPin: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 2.2c-4.6 0-8.2 3.7-8.2 8.2 0 3.8 2 6.8 4 8.8 1 1 2 1.8 2.7 2.3.4.3.7.5.9.6.1.1.2.1.3.2h.1c.2.1.5.1.7 0h.1c.1 0 .1-.1.3-.2.2-.1.5-.3.9-.6.7-.5 1.7-1.3 2.7-2.3 1.9-2 4-5 4-8.8-.3-4.5-3.9-8.2-8.5-8.2Zm0 11.3c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'location-pin',
  type: 'solid'
}
