import { MutationTree } from 'vuex'
import { FormState } from './state'
import {
  ADD_TO_HISTORY,
  CLEAR_ERROR,
  SET_CATEGORY_ID,
  SET_CATEGORY_IDS,
  SET_CLASSIFIED_ID,
  SET_CONFIG,
  SET_EDIT_MODE,
  SET_FIELD_ERRORS_DATA,
  SET_FIELD_SUGGESTIONS,
  SET_FIELD_VALUE,
  SET_FIELD_VALUES,
  SET_FIELD_VALUES_DATA,
  SET_LOADING,
  SET_PAGE,
  SET_ROOT_CATEGORY_ID,
  SET_SCHEMA_DATA,
  SET_SHOW_FLOATING_SAVE_BUTTON,
  SET_TOP_ALERT,
  ADD_CLIENT_VALIDATOR,
  SET_SCHEMA_EXTRAS,
  SET_ALL_CATEGORIES,
  SET_BASE_CATEGORY,
  SET_PAGE_KEY,
  SET_INVALID_TRIGGERED,
  SET_SCHEMA_LOADING,
  SET_USER_TELEPHONES,
  RESET_STATE,
  SET_REQUIRES_PAYMENT,
  SET_TERMS_ACCEPTED,
  SET_WARNING_CONFIRMATION_MESSAGE,
  SET_IS_WARNING_CONFIRMATION_VISIBLE
} from './mutation-types'
import { FieldOptions } from './actions'
import {
  FieldErrors,
  FieldValues,
  TopAlert,
  SchemaResult,
  FieldChange,
  FormConfig,
  HistoryEntry,
  ClientValidator,
  SchemaExtras,
  FormCategory
} from '~/models/classified/form'
import { CompactPage } from '~/models/common/types'
import { createMutationCreator } from '~/factories/store/mutation'
import defaultFormState from '~/store/modules/shared/classifieds/form/state'
import { vue3Delete, vue3Set } from '~/utils/nuxt3-migration'

function invalidFieldNameError() {
  return new TypeError(`Attempting to access field state without a field name`)
}

const { setter } = createMutationCreator<FormState>()

export default {
  ...setter(SET_FIELD_VALUES, 'fieldValues'),
  ...setter(SET_PAGE_KEY, 'pageKey'),
  ...setter(SET_INVALID_TRIGGERED, 'invalidTriggered'),
  [SET_PAGE](state, page: CompactPage) {
    state.page = page
  },
  [SET_CLASSIFIED_ID](state, classifiedId: number) {
    state.classifiedId = classifiedId
  },
  [SET_LOADING](state, loadingState: boolean) {
    state.loading = loadingState
  },
  [SET_SCHEMA_LOADING](state, loadingState: boolean) {
    state.schemaLoading = loadingState
  },
  [SET_ROOT_CATEGORY_ID](state, rootCategoryId: number) {
    state.rootCategoryId = rootCategoryId
  },
  [SET_SCHEMA_DATA](state, schemaData: SchemaResult['schema']) {
    state.fieldsets = schemaData.fieldsets
    state.genericFormFields = schemaData.genericFormFields
    state.title = schemaData.title
  },
  [SET_SCHEMA_EXTRAS](state, schemaExtras?: SchemaExtras) {
    state.extras = schemaExtras
  },
  [SET_FIELD_VALUES_DATA](state, fieldValues: FieldValues) {
    state.fieldValues = fieldValues
  },
  [SET_ALL_CATEGORIES](state, allCategories: FormCategory[]) {
    state.allCategories = allCategories
  },
  [SET_FIELD_ERRORS_DATA](state, fieldErrors: FieldErrors) {
    state.fieldErrors = fieldErrors
  },
  [SET_CONFIG](state, config: FormConfig) {
    state.config = config
  },
  [SET_CATEGORY_ID](state, categoryId: number) {
    state.categoryId = categoryId
  },
  [SET_CATEGORY_IDS](state, categoryIds: number[]) {
    state.categoryIds = categoryIds
  },
  [SET_EDIT_MODE](state, editModeState: boolean) {
    state.editMode = editModeState
  },
  [SET_BASE_CATEGORY](state, category: number) {
    const index = state.allCategories.findIndex(c => c.id === category)
    if (index >= 0) {
      state.allCategories = state.allCategories.map(c => {
        return { ...c, isBase: c.id === category }
      })
    }
  },
  [SET_FIELD_VALUE](state, { name, value, options }: FieldChange) {
    if (!name) {
      throw invalidFieldNameError()
    }

    const langAbbreviation =
      options && options.multilingual && options.multilingual.abbreviation
    if (langAbbreviation) {
      const o: Record<string, any> = { ...state.fieldValues[name] }
      o[langAbbreviation] = value || undefined
      vue3Set(state.fieldValues, name, o)
    } else {
      vue3Set(state.fieldValues, name, value)
    }
  },
  [CLEAR_ERROR](
    state,
    { name, options }: { name: string; value: any; options: FieldOptions }
  ) {
    if (!name) {
      throw invalidFieldNameError()
    }
    const languageAbbreviation =
      options && options.multilingual && options.multilingual.abbreviation
    const fieldError = state.fieldErrors[name]
    if (languageAbbreviation) {
      fieldError &&
        typeof fieldError !== 'string' &&
        fieldError[languageAbbreviation] &&
        vue3Delete(fieldError, languageAbbreviation)
    } else {
      fieldError && vue3Delete(state.fieldErrors, name)
    }
  },
  [SET_SHOW_FLOATING_SAVE_BUTTON](
    state,
    saveFloatingButtonVisibilityState: boolean
  ) {
    state.showFloatingSaveButton = saveFloatingButtonVisibilityState
  },
  [SET_TOP_ALERT](state, { variant, message }: TopAlert) {
    state.topAlert = {
      variant,
      message
    }
  },
  [SET_FIELD_SUGGESTIONS](state, { name, suggestions }) {
    if (!name) {
      throw invalidFieldNameError()
    }
    vue3Set(state.fieldSuggestions, name, suggestions)
  },
  [ADD_TO_HISTORY](state, entry: HistoryEntry) {
    const redoExists =
      state.historyActiveIndex >= -1 &&
      state.historyActiveIndex < state.history.length - 1
    if (redoExists) {
      // if we are trying to add while there is a redo, we should remove all the redos after index
      state.history.splice(
        state.historyActiveIndex + 1,
        state.history.length - state.historyActiveIndex - 1
      )
    }
    state.history.push(entry)
    state.historyActiveIndex = state.history.length - 1
  },
  [ADD_CLIENT_VALIDATOR](state, validator: ClientValidator) {
    state.clientValidators.push(validator)
  },
  [SET_USER_TELEPHONES](state, telephones = []) {
    state.telephones = telephones
  },
  [RESET_STATE](state) {
    Object.assign(state, defaultFormState())
  },
  [SET_REQUIRES_PAYMENT](state, requiresPayment: boolean) {
    state.requiresPayment = requiresPayment
  },
  [SET_TERMS_ACCEPTED](state, termsAccepted: boolean) {
    state.termsAccepted = termsAccepted
  },
  [SET_WARNING_CONFIRMATION_MESSAGE](
    state,
    warningConfirmationMessage: { title: string; text: string }
  ) {
    state.warningConfirmationMessage = warningConfirmationMessage
  },
  [SET_IS_WARNING_CONFIRMATION_VISIBLE](state, isVisible: boolean) {
    state.isWarningConfirmationVisible = isVisible
  }
} as MutationTree<FormState>
