import { OfferType } from '~/models/classified/types'
import { KeyValue } from '~/models/common/types'
import { UserType } from '~/models/user/types'
import { formatAdTargetingToKeyValue } from '~/services/search/formatters'
import { ClassifiedViewState } from '~/store/modules/shared/classifieds/view/state'
import { GetterTreeWithRootState } from '~/store/types'
import { BlacklistReason } from '~/models/blacklist/types'
import { sellerIsOfTypeAgent, sellerIsOfTypeDealer } from '~/utils/user'
import { CategoryId } from '~/models/category/types'
import { MonetaryLeasingOptionField } from '~/models/classified/leasing/types'
import InstallmentsService from '~/services/classified/finance/InstallmentsService'
import { Permission } from '~/constants/permission'
import { ClassifiedRentalInfo } from '~/models/rentals/types'
import { createPhotoUrlWithSize } from '~/utils/photos'
import { ClassifiedViewVariant } from '~/models/classified/view/variant'
import { ShortVideo } from '~/models/short-video'
import { toCamelCase } from '~/utils/object'
import { LoansInfo, OnlineLoanType } from '~/constants/loan/loans'

export default {
  sellerIsBlacklisted(state) {
    return Boolean(state.seller && state.seller.bl)
  },
  sellerIsBlacklistedSuspiciousOrFraudster(state, getters) {
    return (
      state.seller &&
      !getters.isOwn &&
      !getters.sellerIsOfTypeDealer &&
      state.seller.bl &&
      (state.seller.bl === BlacklistReason.SUSPICIOUS ||
        state.seller.bl === BlacklistReason.FRAUDSTER)
    )
  },
  sellerOnlyContactViaMessages(state) {
    return state.seller && state.seller.only_contact_via_messages
  },
  userIsAdminOrOwner(_, getters, __, rootGetters) {
    return getters.isOwn || rootGetters['user/isAdmin']
  },
  canViewPromotionInfo(state, getters, __, rootGetters) {
    return (
      getters.shouldShowProtectedUtilButtons &&
      state.promotion &&
      (state.promotion.available ||
        (!state.promotion.notAvailableForCategory && getters.sellerIsSingle)) &&
      (rootGetters['user/hasPermission'](Permission.CLASSIFIED_PROMOTIONS) ||
        getters.sellerIsSingle) &&
      !state.hidePromotion &&
      !getters.isNonPublic
    )
  },
  hasLeasing(state): boolean {
    return Boolean(state.classified?.leasing?.options)
  },
  hasOneClickAutoTouch(state): boolean {
    return Boolean(
      state.classified?.paidFeatures?.isPaidOneClickAutoTouchEnabled
    )
  },
  isWanted(state) {
    return state.classified && state.classified.offertype === OfferType.WANTED
  },
  isForRent(state) {
    return state.classified && state.classified.offertype === OfferType.RENT
  },
  isBooking(state) {
    return state.classified && state.classified.offertype === OfferType.BOOKING
  },
  isDamaged(state) {
    return (
      state.classified && (state.classified.damaged || state.classified.crashed)
    )
  },
  externalLink(state) {
    return (state.classified && state.classified.external_link) || null
  },
  isNew(state) {
    const supermarketFoodCategoryIds = [
      4528,
      5188,
      4547,
      6522,
      4527,
      3725,
      5188
    ]

    return (
      state.classified &&
      state.classified.condition &&
      state.classified.condition.is_new &&
      !state.classified.category_ids.some(cat =>
        supermarketFoodCategoryIds.includes(cat)
      )
    )
  },
  isDeleted(state) {
    return (
      state.classified &&
      state.classified.states &&
      state.classified.states.is_deleted
    )
  },
  isOwn(state) {
    return Boolean(state.classified && state.classified.own)
  },
  isRemoved(state) {
    return (
      state.classified &&
      state.classified.states &&
      state.classified.states.is_removed
    )
  },
  isNonPublic(state) {
    return (
      state.classified &&
      state.classified.states &&
      state.classified.states.is_non_public
    )
  },
  isHidden(state) {
    return (
      state.classified &&
      state.classified.states &&
      state.classified.states.is_hidden
    )
  },
  isUnlisted(state) {
    return state.classified?.states?.is_unlisted
  },
  isGhosted(state) {
    return state.classified?.states?.is_ghosted
  },
  // these getters use inverted category ids (wanted, offered) on purpose
  isJobWanted(state) {
    return (
      state.classified &&
      state.classified.category_ids.includes(CategoryId.JOBS_OFFERED)
    )
  },
  isJobOffered(state) {
    return (
      state.classified &&
      state.classified.category_ids.includes(CategoryId.JOBS_WANTED)
    )
  },
  isDealersOnly(state) {
    return (
      state.classified &&
      state.classified.states &&
      state.classified.states.dealers_only
    )
  },
  isPlotClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.PLOT)
  },
  isVehicleClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.VEHICLES)
  },
  isXymaClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.XYMA)
  },
  isPartClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.PARTS)
  },
  isBoatClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.BOATS)
  },
  isJobsClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.JOBS)
  },
  isCarClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.CARS)
  },
  isBikeClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.BIKES)
  },
  isVanClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.VANS)
  },
  isPromoted(state) {
    return (
      state.promotion &&
      state.promotion.activePromotion &&
      state.promotion.activePromotion.isPromoted
    )
  },
  isOnlyTrades(state) {
    return state.classified?.trades?.only_trades
  },
  isMarketplaceProduct(state) {
    return !!state.marketplace
  },
  categoryIdsInclude(state) {
    return (id: number) =>
      state.classified &&
      state.classified.category_ids &&
      state.classified.category_ids.includes(id)
  },
  paidClassified(state) {
    return state.paidClassified
  },
  sellerIsDealerOrAgent(_, getters) {
    return getters.sellerIsDealer || getters.sellerIsAgent
  },
  sellerIsDealer(_, getters) {
    return getters.sellerIs(UserType.DEALER)
  },
  sellerIsAnonymous(_, getters) {
    return getters.sellerIs(UserType.ANON)
  },
  sellerIsSingle(_, getters) {
    return getters.sellerIs(UserType.SINGLE)
  },
  sellerIsGuest(_, getters) {
    return getters.sellerIs(UserType.GUEST)
  },
  sellerIsAgent(_, getters) {
    return getters.sellerIs(UserType.AGENT)
  },
  sellerIsConstructionCompany(state) {
    return state.seller && state.seller.agent_type === 'constructor'
  },
  sellerIsManager(_, getters) {
    return getters.sellerIs(UserType.MANAGER)
  },
  sellerIsNonPublicDealer(state) {
    return state.seller && state.seller.npd
  },
  sellerIs(state) {
    return (type: UserType) =>
      state.seller && state.seller.type && state.seller.type === type
  },
  sellerIsOfTypeDealer(state) {
    return state.seller && sellerIsOfTypeDealer(state.seller)
  },
  sellerIsOfTypeAgent(state) {
    return state.seller && sellerIsOfTypeAgent(state.seller)
  },
  getSellerAnalytics(state) {
    return state.seller?.analytics
  },
  sellerAnalyticsV4(state) {
    return state.seller?.analytics_v4
  },
  getKeyValues(state): KeyValue[] {
    return (state.adsTargetings || []).map(formatAdTargetingToKeyValue)
  },
  shouldShowProtectedUtilButtons(_, getters, __, rootGetters) {
    if (getters.sellerIsBlacklisted) {
      return rootGetters['user/isAdmin']
    }
    return getters.userIsAdminOrOwner
  },
  userCanViewNotes(_, __, ___, rootGetters) {
    // Anons, singles and audits users do not have access to notes
    return (
      !rootGetters['user/isAnon'] &&
      !rootGetters['user/isSingle'] &&
      !rootGetters['user/isAuditsUser']
    )
  },
  showMoreClassifiedsFromUser(state) {
    // TODO: format seller in classified view
    // eslint-disable-next-line camelcase
    return state.seller?.show_more_classifieds_from_user
  },
  getLeasingOptionFieldVatValue: state => (
    field: MonetaryLeasingOptionField
  ) => {
    return state.leasing.withVat ? field.valueWithVat : field.valueWithoutVat
  },
  isOnlyLeasing(state) {
    return state.classified?.leasing?.onlyLeasing
  },
  downpaymentIsValid(state) {
    return (installmentsService: InstallmentsService) =>
      installmentsService.downpaymentIsValid(
        state.installments.downpayment,
        state.classified!.finance.options.minDownpayment,
        state.classified!.finance.price
      )
  },
  getDescriptionContent(state) {
    return state.classified?.description?.content ?? ''
  },
  getDescriptionLanguage(state) {
    return state.classified?.description?.language ?? ''
  },
  getShareableDescription(_state, getters) {
    const descriptionContent = getters.getDescriptionContent

    if (!process.client || !descriptionContent) {
      return ''
    }

    const span = document.createElement('span')
    span.innerHTML = descriptionContent
    return span.innerHTML
  },
  photos360(state) {
    return (state?.classified?.compactPhotos.panoramic.photos || []).map(p => ({
      url: createPhotoUrlWithSize(p.url, 'h360'),
      thumb: createPhotoUrlWithSize(p.url, 'm'),
      bigthumb: createPhotoUrlWithSize(p.url, 'm'),
      medium: createPhotoUrlWithSize(p.url, 'm360'),
      name: p.title
    }))
  },
  processedPhotos(state) {
    return state.processedPhotos
  },
  getMonthlyInstallmentAmount(state) {
    return (installmentsService: InstallmentsService) => {
      if (!state.classified?.finance) {
        return null
      }
      const { price, options } = state.classified.finance
      const { downpayment, numberOfInstallments } = state.installments
      const { interestPercentage } = options
      return installmentsService.calculateMonthlyInstallmentAmount(
        price,
        downpayment,
        numberOfInstallments,
        interestPercentage
      )
    }
  },
  shouldDisplayAds(state) {
    return !state.ads?.disabled
  },
  getClassifiedNotes(state) {
    return state.notes
  },
  isRentalClassified(state) {
    return (
      state.classified &&
      state.classified.category_ids.includes(CategoryId.RENTALS)
    )
  },
  rentalInfo(state): ClassifiedRentalInfo | null {
    return state.rental
  },
  classifiedHasNativePhotos(state): boolean {
    return Boolean(state.classified?.compactPhotos.native.photos.length)
  },
  classifiedHasPanoramicPhotos(state): boolean {
    return Boolean(state.classified?.compactPhotos.panoramic.photos.length)
  },
  isDealerSiteVariant(state): boolean {
    return state.variant === ClassifiedViewVariant.DEALER_SITE
  },
  isMapVariant(state): boolean {
    return state.variant === ClassifiedViewVariant.MAP
  },
  isLeasingAvailable(state): boolean {
    return Boolean(state.classified?.leasing?.options)
  },
  isFinanceAvailable(state): boolean {
    return Boolean(state.classified?.finance?.options)
  },
  showFinance(_state, getters): boolean {
    return getters.isLeasingAvailable || getters.isFinanceAvailable
  },
  showLoanSection(state): LoansInfo | undefined {
    return state.classified?.showLoansInfo
  },
  showLoanCalculator(state): boolean {
    const loansInfo = state.classified?.showLoansInfo
    if (!loansInfo) {
      return false
    }
    const loanType = loansInfo.loanType
    const extraLoanInfo = loansInfo.extraLoanInfo
    return Boolean(
      OnlineLoanType.SANTANDER === loanType ||
        ([OnlineLoanType.AUTO, OnlineLoanType.CONSUMER].includes(loanType) &&
          extraLoanInfo.showTbi) ||
        extraLoanInfo.showAlpha
    )
  },
  getClassifiedCode(state): string | undefined {
    const code = state.classified?.specifications.find(
      item => item.name === 'code'
    )
    return code?.value
  },
  getShortVideos(state): ShortVideo[] {
    return toCamelCase(state.classified?.short_videos)
  },
  isWantedPartsClassified(_, getters) {
    return getters.categoryIdsInclude(CategoryId.WANTED_PARTS)
  },
  isInsuranceAvailable(state) {
    return state.insurance?.available
  }
} as GetterTreeWithRootState<ClassifiedViewState>
