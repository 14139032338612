import { Inject } from '@nuxt/types/app'
import { Vue, VueI18n } from '~/utils/nuxt3-migration'
import { LocaleMessages } from 'vue-i18n'
import { I18nLocale } from '~/models/shared/types'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import { SET_LOCALE } from '~/store/modules/shared/i18n/mutation-types'
import { extractCookie } from '~/utils/cookies'
import { appendToResponseHeader } from '~/utils/http'
import { ONE_YEAR } from '~/constants/duration'
import { allLocales } from '~/constants/i18n'

Vue.use(VueI18n)

async function getLocaleMessages(
  activeLocale: string
): Promise<LocaleMessages> {
  switch (activeLocale) {
    case 'en': {
      return {
        // @ts-ignore
        en: await import('../i18n/locales/en.json').then(m => m.default)
      }
    }
    case 'de': {
      return {
        // @ts-ignore
        de: await import('../i18n/locales/de.json').then(m => m.default)
      }
    }
    case 'bg': {
      return {
        // @ts-ignore
        bg: await import('../i18n/locales/bg.json').then(m => m.default)
      }
    }
    case 'el':
    default: {
      return {
        // @ts-ignore
        el: await import('../i18n/locales/el.json').then(m => m.default)
      }
    }
  }
}

export default async (
  { app, store, route, req, res, $config }: any,
  inject: Inject
) => {
  if (process.server && route.name) {
    // We only need to set the locale on the server since the store is
    // serialized on the client when transitioning from server to client

    let locale = I18nLocale.EL
    const {
      public: { domain }
    } = $config

    const langParameter =
      route.query &&
      (Array.isArray(route.query.lang) ? route.query.lang[0] : route.query.lang)

    if (langParameter && allLocales.includes(langParameter)) {
      appendToResponseHeader(res, 'set-cookie', [
        `lang=${encodeURIComponent(
          langParameter
        )}; Path=/; Max-Age=${ONE_YEAR}; Domain=${domain}`
      ])
      locale = langParameter as I18nLocale
    } else if (req.headers.cookie) {
      const langCookie = extractCookie('lang', req.headers)
      if (langCookie) {
        locale = langCookie as I18nLocale
      }
    }

    store.commit(`${I18N_NS}/${SET_LOCALE}`, locale)
  }

  const activeLocale = store.state.i18n.locale

  app.i18n = new VueI18n({
    locale: activeLocale,
    fallbackLocale: 'en',
    messages: await getLocaleMessages(activeLocale),
    silentTranslationWarn: false,
    silentFallbackWarn: true
  })

  inject('i18n', app.i18n)
  inject('t', ((
    key: VueI18n.Path,
    locale: VueI18n.Locale,
    values?: VueI18n.Values
  ) => app.i18n.t(key, locale, values)) as typeof VueI18n.prototype.t)
}
