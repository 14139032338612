import { Report } from '~/models/report'
import { User } from './user/types'
import { SchemaField } from '~/models/form/types'

export enum ShortVideoStatus {
  PENDING = 'pending', // Wait for upload
  PUBLIC = 'public', // Video is visible for all
  DELETED = 'deleted', // Video deleted by user or admin
  BANNED = 'banned', // Video banned by admin
  HIDDEN = 'hidden', // Video hide by owner
  ERROR = 'error' // Error on upload
}

export enum Reactions {
  LIKE = 'like'
}

export interface ShortVideo {
  status: ShortVideoStatus
  isNonPublic: boolean
  isNonPublicReason: string
  isDeleted: boolean
  description: string
  thumbnail: string
  tags: []
  reactions: {
    like: number
  }
  myReactions?: { [key: string]: boolean }
  extras: {
    duration: number
    size: { width: number; height: number }
  }
  classified: ShortsClassified
  playbackUrls: {
    dash: string
    hls: string
  }
  cloudflareVideoId: string
  id: number
  user: User
}

export interface ShortVideosData {
  hidden: ShortVideo[]
  public: ShortVideo[]
  pending: ShortVideo[]
  error: []
}

export interface VideoInfo {
  duration: number
  width: number
  height: number
  coverUrl: string
}

export interface ShortsClassified {
  categories: number[]
  id: number
  title: string
  titleParts: {
    make: string
    variant: string
    model: string
    year: number
  }
  price: number | string
  isPublicVisible: boolean
}

export interface ShortVideoEditFormSchema {
  forClassifiedId: SchemaField
  description: SchemaField
  tags: SchemaField
}

export interface ReactionResponse {
  reaction: Reactions
  created: string
  id: number
  user: User
}

export interface ShortVideoReport extends Report {
  short: ShortVideo
  similarShort: ShortVideo
  viewed: boolean
}

export interface ShortFilters {
  reaction: {
    options: { name: string; value: string }[]
    selected: string
  }
  status: {
    options: { name: string; value: string }[]
    selected: string
  }
  siteRegion: {
    options: { name: string; value: string }[]
    selected: string
  }
  deleted: {
    options: { name: string; value: string }[]
    selected: string
  }
}
