import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__alpha_redirect',
    path: '/alpha-redirect/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/alpha-redirect.vue' /* webpackChunkName: "pages-alpha-redirect.vue" */
        )
      ),
    meta: { noFooter: true, noHeader: true }
  }
]
