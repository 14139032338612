var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CInputGroup',{attrs:{"size":_vm.size,"disabled":_vm.disabled,"has-error":!_vm.isValid},scopedSlots:_vm._u([(_vm.gps)?{key:"append",fn:function(){return [_c('CButton',{class:[
        _vm.disabled
          ? 'tw-cursor-not-allowed'
          : 'tw-cursor-pointer hover:tw-text-blue'
      ],attrs:{"variant":"secondary","tertiary":"","icon":_vm.ciLocationPin},on:{"click":_vm.getCurrentGpsLocation}})]},proxy:true}:null],null,true)},[_c('CInput',{ref:"googleAutocompleteTemplateRef",attrs:{"model-value":_vm.internalValue,"type":"text","placeholder":_vm.placeholder || _vm.$t('location'),"autocomplete":"off","after-allow-events":"","required":_vm.required},on:{"focusin":_vm.onInputFocusIn,"focusout":_vm.onInputFocusOut,"input":_vm.onInputInput},scopedSlots:_vm._u([(_vm.internalValue)?{key:"after",fn:function(){return [_c('div',{staticClass:"tw-text-grey-400 tw-flex tw-transition-colors",class:[
          _vm.disabled
            ? 'tw-bg-grey-200 tw-cursor-not-allowed'
            : 'tw-bg-white tw-cursor-pointer hover:tw-text-red',
          { 'tw-border-t-blue tw-border-b-blue': _vm.inputFocused },
          { 'tw-border-red': _vm.isValid }
        ],attrs:{"role":"button"},on:{"click":_vm.clear}},[_c('CIcon',{staticClass:"tw-ml-2 tw-text-lg",attrs:{"icon":_vm.ciTimes}})],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }