
















































import { Option } from '~/models/shared/types'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch
} from '~/utils/nuxt3-migration'
import { formatNumber } from '~/utils/number'
import { OptionGroup } from '~/components/shared/configurable/form/select/types'

export default defineComponent({
  props: {
    multiSelect: {
      type: Boolean,
      required: true
    },
    internalValue: {
      type: [Array, String, Number],
      default: null
    },
    internalOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    optionGroups: {
      type: Array as PropType<OptionGroup[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: undefined
    },
    tabindex: {
      type: [String, Number],
      default: undefined
    },
    useNativeSelect: {
      type: Boolean,
      required: true
    },
    textField: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const nativeValue = ref()

    watch(
      () => props.internalValue,
      newInternalValue => {
        if (props.multiSelect) {
          nativeValue.value = props.internalValue || []
        } else if (Array.isArray(newInternalValue)) {
          nativeValue.value = newInternalValue.length
            ? newInternalValue[0]
            : null
        } else {
          nativeValue.value = newInternalValue
        }
      },
      { immediate: true, deep: true }
    )

    const nativeOptions = computed(() => {
      const options = [...props.internalOptions]

      if (props.placeholder && props.placeholder !== '') {
        options.unshift({
          value: null,
          text: props.placeholder,
          disabled: true,
          hidden: true,
          selected: false
        })
      }

      return options
    })

    function onChange(event: Event) {
      const el = event.target as HTMLSelectElement

      // FIXME: This seems sketchy but necessary to get the selected value
      const selectedValue = Array.from(el.options || [])
        .filter(o => o.selected)
        .map(o => ('_value' in o ? (o as any)._value : o.value))

      const value = props.multiSelect ? selectedValue : selectedValue[0]

      emit('change', value)
    }

    return {
      nativeOptions,
      nativeValue,
      onChange,
      formatNumber
    }
  }
})
