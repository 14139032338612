













































import { computed, defineComponent, useRoute } from '~/utils/nuxt3-migration'
import BackendFlashMessage from '~/components/car/BackendFlashMessage.vue'
import SaleRequestsCreditsAlert from '~/components/car/sale-requests/SaleRequestsCreditsAlert.vue'
import ClientFlashMessage from '~/components/shared/flash/ClientFlashMessage.vue'
import { useNamespacedStore } from '~/compositions/store'
import { FLASH_NS, FlashState } from '~/store/modules/shared/flash/state'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import CreditsLowAlert from '~/components/car/credits/CreditsLowAlert.vue'
import CreditsDepletedAlert from '~/components/car/credits/CreditsDepletedAlert.vue'
import { creditsWarningGlobalAlertDismissedCookieName } from '~/constants/user/credit'
import { useCookies } from '~/compositions/useCookies'
import SubscriptionExpiryAlert from '~/components/car/subscriptions/SubscriptionExpiryAlert.vue'
import { subscriptionExpiryAlertDismissedCookieName } from '~/constants/subscription'
import { AccessToggleName } from '~/models/user/access-toggles'
import { saleRequestsCreditsGlobalAlertDismissedCookieName } from '~/constants/sale-requests'
import RelatedGuestsAlert from '~/components/car/guests/RelatedGuestsAlert.vue'
import { relatedGuestDealersAlertDismissedCookieName } from '~/constants/user/guests'
import { onMounted } from '@vue/composition-api'

export default defineComponent({
  components: {
    CreditsDepletedAlert,
    CreditsLowAlert,
    ClientFlashMessage,
    SaleRequestsCreditsAlert,
    SubscriptionExpiryAlert,
    BackendFlashMessage,
    RelatedGuestsAlert
  },
  setup() {
    const route = useRoute()
    const cookies = useCookies()
    const { state: flashState } = useNamespacedStore<FlashState>(FLASH_NS)
    const { state: userState, getters: userGetters } = useNamespacedStore<
      UserState
    >(USER_NS)

    const hideCreditsGlobalAlertCookie = cookies.get(
      creditsWarningGlobalAlertDismissedCookieName
    )
    const hideRelatedGuestsAlertCookie = cookies.get(
      relatedGuestDealersAlertDismissedCookieName
    )

    const totalCredits = computed(() => userGetters('totalCredits'))

    const userIsManager = computed(() => userGetters('isManager'))
    const userIsGuest = computed(() => userGetters('isGuest'))

    const userHasAccessToSaleRequestsBuy = computed(() =>
      userGetters('userHasAccessTo')(AccessToggleName.SALE_REQUESTS_BUY)
    )

    const saleRequestsInfo = computed(() => userState.saleRequests)

    const userHasSaleRequestsItems = computed(() =>
      userGetters('hasSaleRequestsItems')
    )

    const subscription = computed(() => userState.subscription)

    const clientFlashMessage = computed(() => flashState.clientFlashMessage)

    const isAccountRoute = computed(() =>
      route.value.name?.startsWith('__account')
    )

    const isIndexRoute = computed(() => route.value.name === '__index')

    const showOutOfCreditsMessage = computed(
      () => typeof totalCredits.value === 'number' && totalCredits.value === 0
    )

    onMounted(() => {
      // delete related guests cookie if we are mounted without a guest (so it can be reset for next login)
      if (
        cookies.get(relatedGuestDealersAlertDismissedCookieName) &&
        !userIsGuest.value
      ) {
        cookies.delete(relatedGuestDealersAlertDismissedCookieName)
      }
    })

    const showLowCreditsMessage = computed(
      () =>
        typeof totalCredits.value === 'number' &&
        totalCredits.value < 5 &&
        totalCredits.value > 0
    )

    const subscriptionExpiresSoon = computed(() => {
      const expiresInDays = subscription.value?.expiresInDays
      if (expiresInDays < 0) {
        return false
      }

      const expiresToday = expiresInDays === 0 && !subscription.value?.expired

      return expiresToday || expiresInDays < 10
    })

    const showSubscriptionExpiryAlert = computed(() => {
      if (
        !subscription.value ||
        !!cookies.get(subscriptionExpiryAlertDismissedCookieName)
      ) {
        return false
      }

      return subscription.value?.expired || subscriptionExpiresSoon.value
    })

    const showSaleRequestsCreditsAlert = computed(() => {
      if (
        !userHasAccessToSaleRequestsBuy.value ||
        !saleRequestsInfo.value ||
        !!cookies.get(saleRequestsCreditsGlobalAlertDismissedCookieName)
      ) {
        return false
      }

      return !saleRequestsInfo.value.fetched && userHasSaleRequestsItems.value
    })

    const relatedGuestDealers = computed(() =>
      userGetters('relatedGuestDealers')
    )

    const showRelatedGuestsAlert = computed(() =>
      Boolean(relatedGuestDealers.value.length)
    )

    return {
      hideCreditsGlobalAlertCookie,
      hideRelatedGuestsAlertCookie,
      clientFlashMessage,
      isIndexRoute,
      userIsManager,
      userIsGuest,
      showOutOfCreditsMessage,
      showLowCreditsMessage,
      subscriptionExpiresSoon,
      showSubscriptionExpiryAlert,
      showSaleRequestsCreditsAlert,
      isAccountRoute,
      showRelatedGuestsAlert
    }
  }
})
