
































import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  vue3Model
} from '~/utils/nuxt3-migration'

export default defineComponent({
  inheritAttrs: true,
  model: vue3Model,
  components: { VueSlider },
  props: {
    modelValue: {
      type: [Number, String, Array],
      required: true
    },
    size: {
      type: String as PropType<'md' | 'lg'>,
      default: 'md'
    },
    variant: {
      type: String as PropType<'primary' | 'secondary' | 'tbi' | 'alpha'>,
      default: 'primary'
    },
    fromLabel: {
      type: String,
      default: ''
    },
    toLabel: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const sliderRef = ref(null)
    function onChange(value: number) {
      emit('change', value)
      emit('update:modelValue', value)
    }

    const isRangeMode = computed(() => Array.isArray(props.modelValue))

    onMounted(async () => {
      await nextTick()
      // also emit change when dots are clicked (even if they are at the same value)
      const sliderDots = sliderRef.value.$el.querySelectorAll('.vue-slider-dot')
      sliderDots.forEach(dot => {
        dot.addEventListener('click', () => {
          onChange(sliderRef.value.getValue())
        })
      })
    })

    return {
      isRangeMode,
      onChange,
      sliderRef
    }
  }
})
