import FlashService from '~/services/flash/FlashService'
import { ClientFlashMessageType } from '~/models/flash/types'

export default function({ store, $dep, $i18n }: any) {
  const isExpiredManager =
    store.getters['user/isManager'] && store.state.user.subscription?.expired

  if (isExpiredManager) {
    const flashService = $dep(FlashService)

    flashService.redirectWithFlash('/', ClientFlashMessageType.GENERIC_ERROR, {
      showAsSnackbar: true,
      snackbarVariant: 'error',
      text: $i18n.t('your_subscription_expired_you_cant_create_classifieds')
    })
  }
}
