var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-container",attrs:{"id":"groupPriceSearchHandler"}},[(_vm.showButtonGroups)?_c('div',{staticClass:"tw-flex tw-justify-between tw-px-2 tw-mb-2"},[_c('CRadioButtonGroup',{staticClass:"tw-w-full",attrs:{"size":"sm","options":_vm.purchaseTypeOptions},on:{"update:modelValue":_vm.handlePurchaseTypeChange},model:{value:(_vm.selectedPurchaseType),callback:function ($$v) {_vm.selectedPurchaseType=$$v},expression:"selectedPurchaseType"}})],1):_vm._e(),_vm._v(" "),_c('RangeSearchHandler',_vm._b({attrs:{"hide-radio-buttons":_vm.isSelectedPurchaseTypeFinance || _vm.isSelectedPurchaseTypeLeasing,"is-nested":""}},'RangeSearchHandler',_vm.legacySearchService.getFacetComponentBindings(_vm.handler),false)),_vm._v(" "),(_vm.isSelectedPurchaseTypeFinance && _vm.financeOptionsFacet.visible)?_c('div',{staticClass:"finance-search-handler-wrapper tw-mt-3"},[_c('FinanceOptionsSearchHandler',_vm._b({attrs:{"is-nested":""}},'FinanceOptionsSearchHandler',
        _vm.legacySearchService.getFacetComponentBindings(_vm.financeOptionsFacet)
      ,false))],1):(_vm.isSelectedPurchaseTypeLeasing && _vm.hasLeasing)?_c('div',{staticClass:"finance-search-handler-wrapper tw-mt-3"},[_c('LeasingOptionsSearchHandler',_vm._b({attrs:{"is-nested":""}},'LeasingOptionsSearchHandler',
        _vm.legacySearchService.getFacetComponentBindings(_vm.leasingOptionsFacet)
      ,false)),_vm._v(" "),_c('LeasingExtrasSearchHandler',_vm._b({staticClass:"tw-mt-2",attrs:{"is-nested":""}},'LeasingExtrasSearchHandler',
        _vm.legacySearchService.getFacetComponentBindings(_vm.leasingExtrasFacet)
      ,false))],1):_vm._e(),_vm._v(" "),(!_vm.isSelectedPurchaseTypeLeasing)?[(_vm.withPriceFacet || _vm.discountFacet)?_c('CCheckboxGroup',{staticClass:"tw-mt-2 tw-mb-0 tw-px-2"},[(_vm.marketplaceFacet)?_c('MarketplaceSearchHandler',_vm._b({attrs:{"is-nested":""}},'MarketplaceSearchHandler',
          _vm.legacySearchService.getFacetComponentBindings(_vm.marketplaceFacet)
        ,false)):_vm._e(),_vm._v(" "),(_vm.withOnlineShoppingFacet)?_c('WithOnlineShoppingSearchHandler',_vm._b({attrs:{"is-nested":""}},'WithOnlineShoppingSearchHandler',
          _vm.legacySearchService.getFacetComponentBindings(
            _vm.withOnlineShoppingFacet
          )
        ,false)):_vm._e(),_vm._v(" "),(_vm.withPriceFacet)?_c('WithPriceSearchHandler',_vm._b({attrs:{"is-nested":""}},'WithPriceSearchHandler',_vm.legacySearchService.getFacetComponentBindings(_vm.withPriceFacet),false)):_vm._e(),_vm._v(" "),(_vm.discountFacet)?_c('DiscountSearchHandler',_vm._b({attrs:{"is-nested":""}},'DiscountSearchHandler',_vm.legacySearchService.getFacetComponentBindings(_vm.discountFacet),false)):_vm._e(),_vm._v(" "),(_vm.wholesalePriceFacet)?_c('WholesalePriceSearchHandler',_vm._b({attrs:{"is-nested":""}},'WholesalePriceSearchHandler',
          _vm.legacySearchService.getFacetComponentBindings(_vm.wholesalePriceFacet)
        ,false)):_vm._e(),_vm._v(" "),(_vm.visibilityFacet)?_c('DealersOnlySearchHandler',_vm._b({attrs:{"is-nested":""}},'DealersOnlySearchHandler',
          _vm.legacySearchService.getFacetComponentBindings(_vm.visibilityFacet)
        ,false)):_vm._e(),_vm._v(" "),(_vm.cheapOnlyFacet)?_c('CheapOnlySearchHandler',_vm._b({attrs:{"is-nested":""}},'CheapOnlySearchHandler',_vm.legacySearchService.getFacetComponentBindings(_vm.cheapOnlyFacet),false)):_vm._e()],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }