












































































import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'
import { mapGetters, mapState } from 'vuex'
import { mapDeps } from '~/plugins/dependency-container/utils'
import CompareService from '~/services/compare/CompareService'
import LegacySearchService from '~/services/search/LegacySearchService'
import Searchbar from '~/components/shared/classified/search/searchbar/Searchbar.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_NS } from '~/store/modules/shared/user/state'
import AlternativeSearches from '~/components/car/classifieds/search/results/AlternativeSearches.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import CClassifiedCompareCounter from '~/components/shared/configurable/classified/compare/CClassifiedCompareCounter.vue'
import ViewTypeSelector from '~/components/car/classifieds/search/results/header/ViewTypeSelector.vue'
import Title from '~/components/car/classifieds/search/results/header/Title.vue'
import SortOptions from '~/components/car/classifieds/search/results/header/SortOptions.vue'
import Pagination from '~/components/car/classifieds/search/results/Pagination.vue'
import SelectedHandlers from '~/components/car/classifieds/search/results/header/SelectedHandlers.vue'
import { CategoryId } from '~/models/category/types'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import MassActionsCategorySelect from '~/components/car/classifieds/search/results/mass-actions/MassActionsCategorySelect.vue'
import { Permission } from '~/constants/permission'
import MakeProductsBanner from '~/components/car/classifieds/search/results/MakeProductsBanner.vue'

export default defineComponent({
  components: {
    MakeProductsBanner,
    MassActionsCategorySelect,
    SelectedHandlers,
    Pagination,
    SortOptions,
    Title,
    ViewTypeSelector,
    Searchbar,
    AlternativeSearches,
    CClassifiedCompareCounter,
    MassActions: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/mass-actions/MassActions.vue'
      )
    ),
    MassRemoveButton: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/header/MassRemoveButton.vue'
      )
    ),
    MassRestoreButton: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/header/MassRestoreButton.vue'
      )
    ),
    AutoRefreshButton: defineVue3AsyncComponent(() =>
      import('~/components/car/wanted-parts/AutoRefreshButton.vue')
    )
  },
  computed: {
    ...mapDeps({
      compareService: CompareService,
      legacySearchService: LegacySearchService,
      dealerSiteService: DealerSiteService
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      alternativeSearches: state => state.alternativeSearches,
      viewType: state => state.viewType,
      title: state => state.title || '',
      shortTitle: state => state.shortTitle,
      longTitle: state => state.longTitle,
      rootCategory: state => state.config.rootParams.category,
      searchBar: state => state.config.settings.searchbar,
      category: state => state.params.category,
      hideViewTypeDesktop: state => state.config.settings.hideViewTypeDesktop,
      allClassifiedsUrl: state => state.allClassifiedsUrl || null,
      isDeletedSearch: state => state.config.settings.isDeletedSearch,
      isCompareButtonVisible: state =>
        state.config.settings.isCompareButtonVisible,
      showSellers: state => state.showSellers
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      qHandler: 'getQFacet',
      inDealsSearch: 'inDealsSearch',
      showSellers: 'showSellers',
      showMassActions: 'showMassActions',
      useNewSearchbar: 'useNewSearchbar',
      isWantedPartsSearch: 'isWantedPartsSearch'
    }),
    ...mapGetters(USER_NS, {
      userIsAdmin: 'isAdmin',
      isOfTypeDealer: 'isOfTypeDealer',
      userHasPermission: 'hasPermission'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    eshopOnboarding() {
      return this.$route.query.eshop_onboarding
    },
    showMassActionCategorySelect() {
      if (!this.showMassActions) {
        const isDealerSiteRoute = this.dealerSiteService.routeIsOfDealerSite()

        return Boolean(
          !isDealerSiteRoute &&
            this.$route.name === '__classifieds_search_my_classifieds' &&
            this.isOfTypeDealer &&
            this.userHasPermission(Permission.MASS_ACTIONS) &&
            this.category
              .map(c => Number(c))
              .includes(CategoryId.CLASSIFIEDS) &&
            !this.isDeletedSearch &&
            !this.eshopOnboarding
        )
      }
      return false
    },
    isViewTypeSelectorVisible() {
      return this.isWantedPartsSearch
        ? false
        : !this.hideViewTypeDesktop && !this.showSellers
    }
  }
})
