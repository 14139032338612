var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{ref:"imgTemplateRef",class:[
    {
      'tw-max-w-full tw-h-auto': _vm.fluid,
      'tw-w-full': _vm.fluidGrow,
      'tw-p-1 tw-bg-white tw-border tw-border-solid tw-border-grey-200': _vm.thumbnail
    },
    _vm.roundedClass,
    _vm.objectFitClass
  ],attrs:{"src":_vm.src,"alt":_vm.alt,"loading":_vm.loadingAttr,"data-src":_vm.src,"draggable":_vm.draggable,"width":_vm.width,"height":_vm.height},on:{"load":function($event){return _vm.$emit('load', $event)},"error":function($event){return _vm.$emit('error', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }