















































































































































































import {
  defineComponent,
  computed,
  toRefs,
  PropType,
  watch,
  ref,
  onBeforeUnmount,
  nextTick,
  onMounted,
  useRoute,
  vue3Model
} from '~/utils/nuxt3-migration'
import { PAGE_NS, PageState } from '~/store/modules/shared/page/state'
import { useNamespacedStore } from '~/compositions/store'
import { useModalToggle } from '~/compositions/modal-toggle'
import { useI18n } from '~/compositions/i18n'
import { TranslateResult } from 'vue-i18n'
import { getRouteMetaItem } from '~/utils/router'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'
import { ButtonSize, ButtonVariant } from '~/models/app/button'
import { ModalSize } from '~/models/app/modal'
import { useUserAgent } from '~/compositions/user-agent'
import { ciTimes } from '~/icons/source/solid/times'

export default defineComponent({
  model: vue3Model,
  components: { CDynamicIcon },
  props: {
    modelValue: { type: Boolean, default: false },
    size: { type: String as PropType<ModalSize>, default: 'md' },
    buttonSize: { type: String as PropType<ButtonSize>, default: 'md' },
    title: { type: String as PropType<string | TranslateResult>, default: '' },
    centered: { type: Boolean, default: false },
    hideBackdrop: { type: Boolean, default: false },
    noFade: { type: Boolean, default: false },
    noCloseOnBackdrop: { type: Boolean, default: false },
    noCloseOnEsc: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
    okDisabled: { type: Boolean, default: false },
    cancelDisabled: { type: Boolean, default: false },
    hideHeaderClose: { type: Boolean, default: false },
    cancelTitle: { type: String, default: '' },
    okTitle: { type: String, default: 'OK' },
    okVariant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary'
    },
    okOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelVariant: {
      type: String as PropType<ButtonVariant>,
      default: 'light'
    },
    cancelOutline: {
      type: Boolean,
      required: false,
      default: true
    },
    dialogClass: { type: [String, Array, Object], default: null },
    containerClass: { type: [String, Array, Object], default: null },
    contentClass: { type: [String, Array, Object], default: null },
    headerClass: { type: [String, Array, Object], default: null },
    closeIconClass: { type: [String, Array, Object], default: null },
    headerContainerClass: { type: [String, Array, Object], default: null },
    bodyClass: { type: [String, Array, Object], default: null },
    footerClass: { type: [String, Array, Object], default: null },
    returnFocus: { type: null, default: null },
    scrollable: { type: Boolean, default: false },
    okOnly: { type: Boolean, default: false },
    modalToggle: { type: Boolean, default: false },
    static: { type: Boolean, default: false },
    hash: { type: String, default: undefined },
    modalInSearch: { type: Boolean, default: false },
    iframeMode: { type: Boolean, default: false },
    displayOnlyLastModal: { type: Boolean, default: true }, // Used for multiple modals
    loading: { type: Boolean, default: false },
    fullscreenMobile: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    icon: { type: Object, default: null }
  },

  setup(props, { emit, slots }) {
    const {
      size,
      visible,
      noCloseOnBackdrop,
      noCloseOnEsc,
      returnFocus,
      static: staticProp,
      modalToggle,
      hash,
      modalInSearch,
      cancelTitle,
      fullscreenMobile,
      fullWidth,
      displayOnlyLastModal
    } = toRefs(props)
    const modalToggleFunc = useModalToggle(
      () => closeModal(null, true),
      () => emit('after-modal-close')
    )

    modalToggleFunc.setHash(hash.value)
    modalToggleFunc.setModalInSearch(modalInSearch.value)
    const { dispatch } = useNamespacedStore<PageState>(PAGE_NS)
    const internalValue = ref(props.modelValue || visible.value)
    const modalContainerRef = ref(null)
    const preventDefaultFlag = ref(false)
    const fadeIn = ref(false)
    const body = ref('body')
    const { t } = useI18n()
    const cancelTitleToShow = computed(() => {
      return cancelTitle.value || t('cancel')
    })

    const route = useRoute()
    const inIframe = computed(() => {
      return Boolean(route.value?.meta?.isIframe)
    })

    const { isMobile } = useUserAgent()

    const isCrossOrigin = computed(() => {
      return getRouteMetaItem(route.value, 'isCrossOrigin')
    })

    onMounted(() => {
      if (internalValue.value) {
        showModal()
      }
    })

    watch(
      () => props.modelValue,
      newValue => {
        if (newValue) {
          showModal()
        } else if (internalValue.value) {
          closeModal()
        }
      }
    )

    watch(hash, () => {
      modalToggleFunc.setHash(hash.value)
    })

    watch(modalInSearch, () => {
      modalToggleFunc.setModalInSearch(modalInSearch.value)
    })

    watch(visible, () => {
      if (visible.value) {
        showModal()
      } else if (internalValue.value) {
        closeModal()
      }
    })

    onBeforeUnmount(() => {
      closeModal('unmount')
    })

    const handleClickBackdrop = () => {
      if (!noCloseOnBackdrop.value) {
        closeModal('backdrop')
      }
    }

    const closeModal = async (
      trigger: string = null,
      fromCallback: boolean = false
    ) => {
      await nextTick()
      if (!internalValue.value) {
        return
      }
      if (preventDefaultFlag.value) {
        preventDefaultFlag.value = false
        return
      }
      emit('hide', { preventDefault, trigger })
      if (modalToggle.value && !fromCallback) {
        modalToggleFunc.onModalHide({ trigger })
      }

      internalValue.value = false
      if (openModalsCount() <= 1 || staticProp.value) {
        removeOpenModalClass()

        if (inIframe.value) {
          handleIframeHide()
        }

        removeBodyHeaderMargin()
        returnFocusToElement()
      }
      removeFromBody()
      emit('update:modelValue', false)
      fadeIn.value = false
      emit('hidden', false)
      showLastModal()
    }

    const handleCloseOnEsc = () => {
      if (noCloseOnEsc.value) {
        return
      }
      closeModal('esc')
    }
    const handleIframeHide = () => {
      if (!isCrossOrigin.value && window.parent && window.parent !== window) {
        window.parent.document.body.classList.remove('modal-open')
      }
    }
    const addOpenModalClass = () => {
      if (!process.client) return
      setBodyHeaderMargin()
      if (modalContainerRef.value) {
        modalContainerRef.value.focus()
      }
      dispatch('addBodyClass', 'modal-open')
    }
    const removeOpenModalClass = () => {
      dispatch('removeBodyClass', 'modal-open')
    }
    const handleCloseBtn = (e: Event) => {
      emit('close', { preventDefault })
      if (!e?.defaultPrevented) {
        closeModal('headerclose')
      } else if (e?.preventDefault) {
        e.preventDefault()
      }
    }

    const bodyScrollbarWidth = () => {
      if (inIframe.value && !isCrossOrigin.value) {
        return (
          window.parent.innerWidth - window.parent.document.body.offsetWidth
        )
      }
      return window.innerWidth - document.body.offsetWidth
    }
    const setBodyHeaderMargin = () => {
      if (!document.body.style.marginRight) {
        const rightMargin = bodyScrollbarWidth()
        document.body.style.marginRight = `${rightMargin}px`
      }
    }
    const removeBodyHeaderMargin = () => {
      document.body.style.marginRight = null
    }
    const hasCustomFooter = computed(() => {
      return !!slots['modal-footer']
    })

    const hasCustomHeader = computed(() => {
      return !!slots['modal-header']
    })

    const hasCustomTitle = computed(() => {
      return !!slots['modal-title']
    })

    if (props.modelValue) {
      addOpenModalClass()
    }

    const handleOk = (e: Event) => {
      emit('ok', { preventDefault })
      if (!e?.defaultPrevented) {
        closeModal('ok')
      } else if (e?.preventDefault) {
        e.preventDefault()
      }
    }
    const handleCancel = (e: Event) => {
      emit('cancel', { preventDefault })
      if (!e?.defaultPrevented) {
        closeModal('cancel')
      } else if (e?.preventDefault) {
        e.preventDefault()
      }
    }

    const showModal = async () => {
      await hideAllOtherModals()
      emit('show', { preventDefault })
      if (modalToggle.value) {
        modalToggleFunc.modalOpen()
      }
      if (preventDefaultFlag.value) {
        preventDefaultFlag.value = false
        return
      }

      internalValue.value = true

      if (!staticProp.value) {
        await appendToBody()
      }
      addOpenModalClass()

      setTimeout(() => (fadeIn.value = true))

      emit('update:modelValue', true)
      emit('shown')
    }
    // Used for multiple modals
    const hideAllOtherModals = async () => {
      if (!displayOnlyLastModal.value) {
        return
      }
      await nextTick()
      const arrayOfModals = getAllModals()

      if (arrayOfModals.length) {
        arrayOfModals.map(m => (m.style.display = 'none'))
      }
    }

    // Used for multiple modals
    const showLastModal = () => {
      const arrayOfModals = getAllModals()
      if (arrayOfModals.length) {
        arrayOfModals[arrayOfModals.length - 1].style.display = ''
      }
    }

    const getAllModals = () => {
      return Array.prototype.slice
        .call(document.body.children)
        .filter(c => c.localName === 'div' && c.dataset.modal)
    }

    const appendToBody = async () => {
      await nextTick()
      document.querySelector('body').appendChild(modalContainerRef.value)
    }

    const removeFromBody = () => {
      if (modalContainerRef?.value?.remove && !staticProp.value) {
        modalContainerRef.value.remove()
      }
    }

    const openModalsCount = () => {
      return Array.from(document.body.children).filter(
        (c: HTMLElement) => c.dataset.modal
      ).length
    }

    const returnFocusToElement = () => {
      if (returnFocus.value && typeof returnFocus.value === 'object') {
        returnFocus.value.focus()
      } else if (typeof returnFocus.value === 'string') {
        const el = document.getElementById(returnFocus.value.replace('#', ''))
        if (el) {
          el.focus()
        }
      }
    }
    const sizeClass = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full tw-max-w-[100%]'
      }
      let maxWidthClass = 'tw-max-w-[532px]'

      if (fullscreenMobile.value) {
        maxWidthClass = isMobile.value
          ? 'lg:tw-max-w-[532px]'
          : 'tw-max-w-[577px]'
      }

      switch (size.value) {
        case 'xl':
          return 'xl:tw-max-w-[1170px] lg:tw-max-w-[1000px] md:tw-max-w-[532px] sm:tw-max-w-[532px] tw-min-w-0 tw-w-full'
        case 'lg':
          return 'lg:tw-max-w-[830px] md:tw-max-w-[532px] sm:tw-max-w-[532px] tw-min-w-0 tw-w-full'
        case 'sm':
          return 'sm:tw-max-w-[420px] tw-min-w-0 tw-w-full'
        default:
          return `${maxWidthClass} tw-min-w-0`
      }
    })

    const preventDefault = () => (preventDefaultFlag.value = true)

    return {
      ciTimes,
      sizeClass,
      hasCustomFooter,
      hasCustomHeader,
      hasCustomTitle,
      internalValue,
      modalContainerRef,
      handleCloseOnEsc,
      fadeIn,
      handleClickBackdrop,
      handleCloseBtn,
      closeModal,
      handleOk,
      handleCancel,
      showModal,
      hide: closeModal,
      show: showModal,
      body,
      cancelTitleToShow,
      inIframe,
      handleIframeHide,
      isMobile
    }
  }
})
