export const sentryToken = Symbol('sentryValueInjectionToken')
export const httpToken = Symbol('httpValueInjectionToken')
export const requestToken = Symbol('requestValueInjectionToken')
export const responseToken = Symbol('responseValueInjectionToken')
export const requestContainerToken = Symbol(
  'requestContainerValueInjectionToken'
)
export const redirectToken = Symbol('redirectValueInjectionToken')
export const vueRouterToken = Symbol('vueRouterValueInjectionToken')
export const vueI18nToken = Symbol('vueI18nValueInjectionToken')
export const storeToken = Symbol('storeValueInjectionToken')
export const configToken = Symbol('configValueInjectionToken')
