import { isEmail } from '~/utils/string'
import { GetterTreeWithRootState } from '~/store/types'
import { UserType } from '~/models/user/types'
import { UserState } from './state'
import { AccessToggleName } from '~/models/user/access-toggles'
import { SubscriptionPlanName } from '~/models/user/subscription'
import { SubscriptionTier } from '~/models/search/subscriptions/types'

export default {
  is(state) {
    return (type: UserType) => state.type === type
  },
  isAdmin(state) {
    return state.type === UserType.ADMIN
  },
  isGuest(state) {
    return state.type === UserType.GUEST
  },
  isManager(state) {
    return state.type === UserType.MANAGER
  },
  isDealer(state) {
    return state.type === UserType.DEALER
  },
  isAgent(state) {
    return state.type === UserType.AGENT
  },
  isAnon(state) {
    return state.type === UserType.ANON
  },
  isSingle(state) {
    return state.type === UserType.SINGLE
  },
  externalIds(state) {
    return state.externalIds
  },
  isSingleOrAnon(state) {
    return state.type === UserType.SINGLE || state.type === UserType.ANON
  },
  isOfTypeDealer(_, getters) {
    return getters.isDealer || getters.isManager || getters.isAgent
  },
  isOfTypeAgent(_, getters) {
    return getters.isManager || getters.isAgent
  },
  isPayingUser(state) {
    return state.isPayingUser
  },
  userType(state) {
    return state.type
  },
  userId(state) {
    return state.id
  },
  marketplaceBuyerId(state) {
    return state.marketplaceIds?.buyerId || null
  },
  marketplaceSellerId(state) {
    return state.marketplaceIds?.sellerId || null
  },
  marketplaceOrdersCount(state) {
    return state.marketplace?.buyer?.ordersCount || 0
  },
  marketplaceSellerNewOrdersCount(state) {
    return state.marketplace?.seller?.newOrdersCount || 0
  },
  marketplaceSellerIsOnboarded(state) {
    return Boolean(
      state.marketplace?.seller?.onboardingStatus &&
        state.marketplace?.seller.onboardingStatus.balancePurchase &&
        state.marketplace?.seller.onboardingStatus.products &&
        state.marketplace?.seller.onboardingStatus.returnPolicy &&
        state.marketplace?.seller.onboardingStatus.shippingOptions &&
        state.marketplace?.seller.onboardingStatus.settings &&
        state.marketplace?.seller.onboardingStatus.stripe
    )
  },
  marketplaceCartItemsCount(state) {
    return state.marketplace?.buyer?.cartItemsCount || 0
  },
  userDealerIds(state) {
    return state.dealerIds || []
  },
  userIds(_state, { userId, userDealerIds }) {
    return [userId, ...userDealerIds]
  },
  userMayReceiveCreditAlerts(_state, { isDealer, isManager }): boolean {
    return isDealer || isManager
  },
  referenceCode(state) {
    return state.referenceCode || null
  },
  userHasFeatureToggle(state) {
    return (toggle: string) => state.featureToggles?.includes(toggle)
  },
  hasPermission(state) {
    return (permission: string) =>
      state.permissions.some(perm => perm === permission)
  },
  usernameIsEmail(state) {
    return state.username && isEmail(state.username)
  },
  hasPublicDealers(state) {
    return state.hasPublicDealers || false
  },
  totalCredits({ credits }) {
    return credits && credits.paid + credits.free
  },
  paidCredits({ credits }) {
    return credits && credits.paid
  },
  freeCredits({ credits }) {
    return credits && credits.free
  },
  relatedGuestDealers({ relatedToDealers }) {
    return relatedToDealers || []
  },
  myClassifiedsCount(state) {
    if (state.myClassifieds) {
      return state.myClassifieds.reduce((accumulator, object) => {
        let count = 0
        if (object.type !== 'deleted') {
          count = object.count
        }
        return accumulator + count
      }, 0)
    }
    return 0
  },
  creditRenewalPageUrl: ({ type }): string | undefined => {
    if (type === UserType.MANAGER || type === UserType.DEALER) {
      return '/account/renew/'
    }

    return undefined
  },
  userHasAccessTo(state): (a: AccessToggleName) => boolean {
    return (accessToggleName: AccessToggleName) => {
      const toggle = state.accessToggles.find(t => t.value === accessToggleName)
      return Boolean(toggle && toggle.allow)
    }
  },
  isDebugUser(state): boolean {
    return state.sdmu
  },
  hasCompleteProfile(state): boolean {
    return Boolean(state.hasCompleteProfile)
  },
  isAuditsUser(_state, getters): boolean {
    return getters.isAuditProvider || getters.isAuditShop || getters.isAuditor
  },
  isAuditProvider(state): boolean {
    return state.type === UserType.AUDIT_PROVIDER
  },
  isAuditShop(state): boolean {
    return state.type === UserType.AUDIT_SHOP
  },
  isAuditor(state): boolean {
    return state.type === UserType.AUDITOR
  },
  userCanCreateClassifieds(_state, getters): boolean {
    return !(getters.isAdmin || getters.isAuditsUser)
  },
  userHasAuditRequests(state): boolean {
    return Boolean(state.auditRequestsCount?.all)
  },
  getAgentSubscription(
    state,
    getters
  ): SubscriptionPlanName | null | undefined {
    return getters.isAgent ? state.stripeSubscription?.tier : null
  },
  getHasParked(state): boolean {
    return state.hasParked
  },
  perksTier(state): SubscriptionTier | undefined {
    return state.perks?.tier
  },
  isAlphaTester(state): boolean {
    return Boolean(state.loans?.alphaTester)
  },
  userCanViewPriceStats(_state, getters) {
    // TODO: enable this when the subscription tiers are available
    // const userHasAccessToPriceStats = [
    //   SubscriptionTier.BASIC,
    //   SubscriptionTier.PRO
    // ].includes(getters.perksTier)

    return getters.isAdmin || getters.isManager
  },
  hasSaleRequestsItems(state): boolean {
    return state.saleRequests?.hasItems || false
  }
} as GetterTreeWithRootState<UserState>
