import { SearchOfBucket } from '~/models/search/types'
import { ParkingState } from '~/store/modules/shared/parking/state'
import { GetterTreeWithRootState } from '~/store/types'

export default {
  getGroups(state) {
    return state.groups
  },
  getError(state) {
    return state.error
  },
  getSearch(state) {
    return (searchId: string) =>
      state.searches &&
      state.searches.find(search => search.query_id === searchId)
  },
  getSearches(state) {
    return state.searches
  },
  getLoading(state) {
    return state.loading
  },
  getRecent(state) {
    return state.recent
  },
  getActiveTab(state) {
    return state.activeTab
  },
  getClassifiedsList(state) {
    return state.classifiedsList
  },
  getCompareList(state) {
    return state.compareList
  },
  getShowParking(state) {
    return state.showParking
  },
  getParkUrlBefore(state) {
    return state.parkUrlBefore
  },
  getParkNotifications(state) {
    return state.parkingNotifications
  },
  searchIsFavorite: ({ searches }) => (search: SearchOfBucket) => {
    if (!searches) {
      return false
    }

    for (const s of searches) {
      if (s.query_id === search.query_id) {
        return true
      }
    }

    return false
  },
  showCompare(state) {
    return state.compareList?.length && state.activeTab === 'classifieds'
  }
} as GetterTreeWithRootState<ParkingState>
