



























































































import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
// @ts-ignore
import VueSocialSharing from 'vue-social-sharing'
import {
  faFacebookF,
  faFacebookMessenger,
  faViber,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import UrlShortenerService from '~/services/url/UrlShortenerService'
import { copyTextToClipboard } from '~/utils/dom'
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs
} from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { useUserAgent } from '~/compositions/user-agent'
import { useSnackbar } from '~/compositions/snackbar'
import { ciXNoBg } from '~/icons/source/brand/x-no-bg'
import { blobUrlToFile } from '~/compositions/files/utils'
import { useLogger } from '~/compositions/logger'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'

export default defineComponent({
  components: { 'social-sharing': VueSocialSharing },
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: ''
    },
    // Pass this prop to bypass url shortening and use this url instead
    url: {
      type: String,
      default: null
    },
    imageToShare: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { url, title, description, imageToShare } = toRefs(props)

    const shareUrl = ref(window.location.href)
    const supportsNavigatorShare = ref(false)

    const urlShortenerService = useDep(UrlShortenerService)
    const { t } = useI18n()
    const { isPc } = useUserAgent()
    const snackbar = useSnackbar()
    const logger = useLogger()

    onMounted(async () => {
      supportsNavigatorShare.value = 'share' in window
      if (url.value) {
        shareUrl.value = url.value
        return
      }

      try {
        const { shortUrl } = await urlShortenerService.shorten(
          window.location.href
        )
        shareUrl.value = shortUrl
      } catch (err) {
        shareUrl.value = window.location.href
      }
    })

    const facebookAppId = computed(() => '184669805568954')

    const messengerLink = computed(() =>
      isPc.value
        ? `https://www.facebook.com/dialog/send?app_id=${facebookAppId.value}&link=${shareUrl.value}&redirect_uri=${shareUrl.value}`
        : `fb-messenger://share/?link=${shareUrl.value}&app_id=${facebookAppId.value}`
    )

    const whatsappLink = computed(() => `https://wa.me/?text=${shareUrl.value}`)

    function copyToClipboard() {
      copyTextToClipboard(shareUrl.value)
      snackbar.success(t('link copied to clipboard'))
    }

    async function nativeShare() {
      let filesToShare = []
      if (imageToShare.value) {
        try {
          const file = await blobUrlToFile(
            imageToShare.value as string,
            title.value
          )
          filesToShare = [file]
        } catch (e) {
          logger.captureError(e)
        }
      }

      const shareData = {
        files: filesToShare,
        url: shareUrl.value,
        title: title.value,
        text: description.value
      }

      if (navigator.canShare && navigator.canShare(shareData)) {
        navigator.share(shareData)
      } else {
        supportsNavigatorShare.value = false
      }
    }

    const networks = computed(() => {
      const basicNetworks = [
        {
          name: 'email',
          icon: faEnvelope,
          variant: 'blue',
          title: 'Email'
        },
        {
          name: 'facebook',
          icon: faFacebookF,
          variant: 'facebook',
          title: 'Facebook'
        },
        {
          name: 'twitter',
          fa: true,
          icon: ciXNoBg,
          variant: 'x',
          title: 'X'
        },
        {
          name: 'whatsapp',
          icon: faWhatsapp,
          variant: 'whatsapp',
          title: 'Whatsapp'
        },
        {
          name: 'messenger',
          icon: faFacebookMessenger,
          variant: 'light',
          class: 'text-messenger',
          title: 'Messenger'
        },
        {
          name: 'viber',
          icon: faViber,
          variant: 'viber',
          title: 'Viber'
        },
        {
          name: 'clipboard',
          icon: faLink,
          variant: 'blue',
          title: t('url')
        }
      ]

      if (supportsNavigatorShare.value) {
        basicNetworks.push({
          name: 'native',
          icon: faEllipsisH,
          variant: 'light',
          title: t('share')
        })
      }

      return basicNetworks
    })

    return {
      shareUrl,
      networks,
      copyToClipboard,
      nativeShare,
      messengerLink,
      whatsappLink,
      supportsNavigatorShare
    }
  }
})
