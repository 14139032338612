import { CIcon } from '~/icons/types'

export const ciCheck: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M20.055 4.418a1 1 0 0 1 .277 1.387l-9 13.5a1 1 0 0 1-1.54.152l-6-6a1 1 0 1 1 1.415-1.414l5.138 5.137 8.323-12.485a1 1 0 0 1 1.387-.277Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'check',
  type: 'regular'
}
