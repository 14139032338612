import { CIcon } from '~/icons/types'

export const ciExclamationCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 15c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm0-2c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1Z'
    },
    {
      d:
        'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'exclamation-circle',
  type: 'regular'
}
