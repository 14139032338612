import '~/polyfills/reflect-metadata' // Tsyringe requirement
import { container } from 'tsyringe'
import {
  createDepGetter,
  createArgDepGetter
} from '~/plugins/dependency-container/utils'
import {
  configToken,
  httpToken,
  redirectToken,
  requestContainerToken,
  requestToken,
  responseToken,
  sentryToken,
  storeToken,
  vueI18nToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

export default function(
  {
    app: { $sentry: sentry, i18n, router },
    store,
    $axios,
    req,
    res,
    redirect,
    $config
  }: any,
  inject: any
) {
  const requestContainer = container.createChildContainer()

  requestContainer.register(requestToken, { useValue: req || false })
  requestContainer.register(responseToken, { useValue: res || false })
  requestContainer.register(redirectToken, { useValue: redirect })
  requestContainer.register(sentryToken, { useValue: sentry })
  requestContainer.register(requestContainerToken, {
    useValue: requestContainer
  })
  requestContainer.register(httpToken, { useValue: $axios })
  requestContainer.register(vueRouterToken, { useValue: router })
  requestContainer.register(vueI18nToken, { useValue: i18n })
  requestContainer.register(storeToken, { useValue: store })
  requestContainer.register(configToken, { useValue: $config })

  inject('requestContainer', requestContainer)
  inject('dep', createDepGetter(requestContainer))
  inject('deps', createArgDepGetter(requestContainer))
}
