import { CIcon } from '~/icons/types'

export const ciHome: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm22.5 11.3-9-9c-.8-.8-2.2-.8-3 0l-9 9c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l.5-.5v7.7c0 1.2 1 2.1 2.1 2.1h12.8c1.2 0 2.1-1 2.1-2.1v-7.7l.5.5c.4.4 1 .4 1.4 0 .4-.4.5-1 .2-1.4ZM13.2 20h-2.5v-3.9c0-.1.1-.1.1-.1H13c.1 0 .1.1.1.1V20h.1Zm5.3-.1c0 .1-.1.1-.1.1h-3.1v-3.9c0-1.2-1-2.1-2.1-2.1H11c-1.2 0-2.1 1-2.1 2.1V20H5.6c-.1 0-.1-.1-.1-.1v-9.7l6.4-6.4h.2l6.4 6.4v9.7Z',
      fill: 'currentColor'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'home',
  type: 'regular'
}
