















import { defineComponent } from '~/utils/nuxt3-migration'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  components: { COptionWithCount },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValue: false,
      count: null
    }
  },
  computed: {
    faShoppingCart() {
      return faShoppingCart
    }
  },
  methods: {
    onBackendValues() {
      const [searchable] = this.searchables

      this.selectedValue = !!searchable.s
      this.count = searchable.c
    },
    handleValueChange(value) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    }
  },
  i18n: defineComponentTranslations({
    buy_online: {
      en: 'Buy online',
      el: 'Online αγορά'
    }
  })
})
