
















import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LegacySearchService from '~/services/search/LegacySearchService'
import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import OfferTypeLeasingSearchHandler from '~/components/car/classifieds/search/facets/handlers/OfferTypeLeasingSearchHandler.vue'
import { Facet } from '~/models/search/types'
import OfferTypeSearchHandler from '~/components/car/classifieds/search/facets/handlers/OfferTypeSearchHandler.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    OfferTypeLeasingSearchHandler,
    OfferTypeSearchHandler
  },
  extends: BaseSearchHandler,
  computed: {
    ...mapDeps({ legacySearchService: LegacySearchService }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isMapSearch: state => state.flags.isMapSearch
    }),
    leasingOptionsFacet(): Facet {
      return this.getFacetByName('leasing_options')
    },
    hasLeasing() {
      return (
        this.leasingOptionsFacet &&
        this.leasingOptionsFacet.visible &&
        !this.isMapSearch
      )
    }
  },
  methods: {
    onClear() {
      this.emitParams([
        { name: 'offer_type', value: null },
        { name: 'finance', value: null }
      ])
    }
  }
})
