import { CIcon } from '~/icons/types'

export const ciHome: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm22.3 11.5-8.7-8.7c-.9-.9-2.3-.9-3.2 0l-8.7 8.7c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l8.7-8.7c.3-.3.8-.3 1.1 0l8.7 8.7c.3.3.8.3 1.1 0 .2-.4.2-.8-.1-1.1Z'
    },
    {
      d:
        'm12 5.4-8.2 8.2-.1.1v6.2c0 1 .8 1.9 1.9 1.9H9c.4 0 .8-.3.8-.8v-4.5c0-.4.3-.8.8-.8h3c.4 0 .8.3.8.8V21c0 .4.3.8.8.8h3.4c1 0 1.9-.8 1.9-1.9v-6.2l-.1-.1L12 5.4Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'home',
  type: 'solid'
}
