




























import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import {
  computed,
  defineComponent,
  useRoute,
  useRouter
} from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { THREE_MONTHS } from '~/constants/duration'
import { useCookies } from '~/compositions/useCookies'
import { relatedGuestDealersAlertDismissedCookieName } from '~/constants/user/guests'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  setup() {
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const cookies = useCookies()
    const router = useRouter()
    const route = useRoute()

    const relatedGuestDealers = computed(() =>
      userGetters('relatedGuestDealers')
    )

    const relatedUsername = computed(() => {
      if (relatedGuestDealers.value.length === 1) {
        return relatedGuestDealers.value[0].username
      }
      return ''
    })

    function onDismiss() {
      cookies.set(relatedGuestDealersAlertDismissedCookieName, 1, {
        maxAge: THREE_MONTHS
      })
    }

    const loginUrl = computed(() => {
      return router.resolve({
        name: '__login',
        query: {
          gotonext: route.value.path,
          username: relatedUsername.value ? relatedUsername.value : undefined
        }
      }).href
    })

    const logoutUrl = computed(() => {
      return router.resolve({
        name: '__logout',
        query: { gotonext: loginUrl.value }
      }).href
    })

    return {
      relatedGuestDealers,
      loginUrl,
      relatedUsername,
      onDismiss,
      logoutUrl
    }
  },
  i18n: defineComponentTranslations({
    related_guests_title: {
      en: 'A dealer account was found for this email',
      el: "Βρέθηκε εμπορικός λογαριασμός γι' αυτό το email"
    },
    related_guests_title_multiple: {
      en: 'Dealer accounts were found for this email',
      el: "Βρέθηκαν εμπορικοί λογαριασμοί γι' αυτό το email"
    },
    related_guests_body: {
      en:
        'If you want, you can log out and sign in to your dealer account by clicking the button below.',
      el:
        'Αν θέλεις, μπορείς να αποσυνδεθείς και να συνδεθείς με τον εμπορικό λογαριασμό σου πατώντας στο κουμπί παρακάτω. '
    },
    related_guests_action_username: {
      en: 'Log in as {username}',
      el: 'Σύνδεση ως {username}'
    },
    related_guests_action: {
      en: 'Log in with dealer account',
      el: 'Σύνδεση με εμπορικό λογαριασμό'
    }
  })
})
