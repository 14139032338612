import { del, set, defineComponent } from '@nuxtjs/composition-api'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

export const vue3Model = {
  prop: 'modelValue',
  event: 'update:modelValue'
}

export function defineVue3AsyncComponent(component: () => Promise<any>) {
  // TODO Nuxt3: use `defineAsyncComponent` from Vue 3
  return component
}

export function vue3Set(
  obj: Record<string | number | symbol, any>,
  key: any,
  value: any
) {
  // TODO Nuxt3: Replace this with obj[key] = value
  set(obj, key, value)
}

export function vue3Delete(
  obj: Record<string | number | symbol, any>,
  key: any
) {
  // TODO Nuxt3: Replace this with delete obj[key]
  del(obj, key)
}

// TODO Nuxt3: Replace this with 'beforeMount'
export const vue3DirectiveBind = 'bind'

// TODO Nuxt3: Replace this with 'unmounted'
export const vue3DirectiveUnbind = 'unbind'

// TODO Nuxt3: Replace this with 'updated'
export const vue3DirectiveComponentUpdated = 'componentUpdated'

// TODO Nuxt3: Replace this with 'updated'
export const vue3DirectiveUpdate = 'update'

export * from '@nuxtjs/composition-api'

export { defineComponent as defineNuxtComponent }

export const useFetchState = (_key: string, initialValue: any) => {
  // TODO Nuxt3: Replace this with `useState` from nuxt
  return initialValue
}

export { Vue, VueRouter, VueI18n }
