import {
  SET_CURRENT_USER_IS_PARKED,
  SET_DATA,
  SET_REFS,
  SET_EXTERNAL_CERTIFICATE,
  SET_PROCESSED_PHOTOS,
  SET_CLASSIFIED_NOTES,
  RESET_STATE,
  SET_VARIANT,
  SET_DEBUG_SHIMMER,
  SET_CLASSIFIED_ID,
  SET_HAS_OFFERS_FOR_CLASSIFIED,
  SET_CLASSIFIED_OFFERS,
  SET_OFFER_COUNT,
  SET_AUDIT_REQUESTS,
  SET_CONTACT_FORM_DATA,
  SET_AUDITS
} from '~/store/modules/shared/classifieds/view/mutation-types'
import { ClassifiedViewState } from '~/store/modules/shared/classifieds/view/state'
import { ActionTreeWithRootState } from '~/store/types'
import { handleError, setUrlParam } from '~/utils/http'
import { CLASSIFIED_ACTIONS_NS } from '~/store/modules/shared/classifieds/actions/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  MODIFY_ROW_PARKED,
  MODIFY_ROW_TOUCHED
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { SET_CONSENT } from '~/store/modules/shared/page/mutation-types'
import { HttpStatus } from '~/constants/http'
import { CustomErrorType } from '~/models/error'
import { executeSyncOnServerAsyncOnClient } from '~/utils/function'
import ClassifiedService from '~/services/classified/ClassifiedService'
import ClassifiedExternalCertificatesService from '~/services/classified/ClassifiedExternalCertificatesService'
import InstallmentsService from '~/services/classified/finance/InstallmentsService'
import LeasingService from '~/services/classified/finance/LeasingService'
import { ClassifiedNote } from '~/models/classified/note'
import { createPhotoUrlWithSize } from '~/utils/photos'
import { ClassifiedViewVariant } from '~/models/classified/view/variant'
import ScrollService from '~/services/scroll/ScrollService'
import ClassifiedOffersService from '~/services/classified/ClassifiedOffersService'
import { AxiosError } from 'axios'
import { OfferState } from '~/models/classified/offers'
import AuditRequestService from '~/services/audits/AuditRequestService'
import { ContactFormData } from '~/models/classified/view'

export default {
  async createClassifiedView(
    { dispatch, commit },
    {
      slugOrId,
      variant
    }: { slugOrId: string; variant: ClassifiedViewVariant } = {
      slugOrId: '',
      variant: ClassifiedViewVariant.DEFAULT
    }
  ) {
    if (!slugOrId) {
      return
    }
    const classifiedId: number = parseInt(
      slugOrId
        .toString()
        .split('-')[0]
        .replace('/', '')
    )

    commit(RESET_STATE, null)
    commit(SET_VARIANT, variant)
    commit(
      SET_DEBUG_SHIMMER,
      Boolean(this.$router.currentRoute.query['debug-shimmer'])
    )
    commit(SET_CLASSIFIED_ID, classifiedId)
    await dispatch('loadClassifiedView')
  },
  loadClassifiedView({ state, dispatch, commit, getters }) {
    const createParams = () => {
      const params = this.$router.currentRoute.query
      if (getters.isDealerSiteVariant) {
        params.dsite = '1'
      }
      return params
    }

    return executeSyncOnServerAsyncOnClient(async () => {
      const [
        classifiedService,
        installmentsService,
        leasingService
      ] = this.$deps(ClassifiedService, InstallmentsService, LeasingService)
      const params = createParams()

      try {
        const data = await classifiedService.view(state.classifiedId!, params)
        const {
          classified: { finance, leasing }
        } = data
        if (finance) {
          installmentsService.setClassifiedViewInstallmentsDefaults(
            finance,
            params
          )
        }
        if (leasing) {
          leasingService.setClassifiedViewLeasingDefaults(leasing, params)
        }

        commit(SET_DATA, data)
        commit(`${PAGE_NS}/${SET_CONSENT}`, state.consent, {
          root: true
        })

        await dispatch('processPhotos')
      } catch (error) {
        await dispatch('handleError', error)
      }
    })
  },
  processPhotos({ state, commit, getters }) {
    const bigSize = 'b'
    const mediumSize = 'z'
    const thumbSize = getters.isJobsClassified ? 'n' : 'v'

    const photos360 = getters.photos360
    const nativePhotos = state.classified?.compactPhotos.native.photos
    const userPhotos = state.classified?.compactPhotos.userPhotos.photos
    const knowledgebasePhotos =
      state.classified?.compactPhotos.knowledgebasePhotos.photos
    const processed = []
    const videos = state.classified?.videos

    // if we have 360 then the first image must be the first 360, then all the others
    if (photos360?.length) {
      processed.push({
        type: 'img360',
        url: photos360[0].url,
        medium: photos360[0].medium,
        thumb: photos360[0].thumb,
        bigthumb: photos360[0].bigthumb,
        name: photos360[0].name
      })
    }

    if (nativePhotos) {
      nativePhotos.forEach(photo => {
        processed.push({
          type: 'img',
          medium: createPhotoUrlWithSize(photo.url, mediumSize),
          big: createPhotoUrlWithSize(photo.url, bigSize),
          thumb: createPhotoUrlWithSize(photo.url, thumbSize),
          name: photo.title
        })
      })
    }

    if (userPhotos) {
      userPhotos.forEach(photo => {
        processed.push({
          type: 'img',
          medium: photo.url,
          big: photo.url,
          thumb: photo.url,
          name: photo.title
        })
      })
    }

    if (knowledgebasePhotos) {
      knowledgebasePhotos.forEach(photo => {
        processed.push({
          type: 'img',
          medium: photo.url,
          big: photo.url,
          thumb: photo.url,
          name: photo.title
        })
      })
    }

    if (videos && videos.length) {
      const firstVideo = videos[0]
      processed.push({
        type: 'video',
        link: `https://www.youtube.com/embed/${firstVideo.url}`,
        thumb: `https://img.youtube.com/vi/${firstVideo.url}/default.jpg`,
        medium: `https://img.youtube.com/vi/${firstVideo.url}/mqdefault.jpg`,
        big: `https://img.youtube.com/vi/${firstVideo.url}/hqdefault.jpg`,
        id: firstVideo.url
      })
    }

    commit(SET_PROCESSED_PHOTOS, processed)
  },
  handleError({ state }, error) {
    const status = error.response && error.response.status
    const errorMessage =
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error

    if (!error.response) {
      this.$logger.captureError(error)
    }

    switch (status) {
      case HttpStatus.GONE: {
        this.$error({
          statusCode: HttpStatus.GONE,
          type: CustomErrorType.CLASSIFIED_VIEW_GONE,
          meta: { classifiedId: state.classifiedId },
          message: errorMessage
        })
        break
      }
      case HttpStatus.NOT_FOUND: {
        this.$error({ message: errorMessage, statusCode: status })
        break
      }
      case HttpStatus.TOO_MANY_REQUESTS: {
        this.$error({ message: errorMessage, statusCode: status })
        break
      }
      default: {
        this.$error({ message: errorMessage, statusCode: status })
      }
    }
  },
  setParked({ commit }, data) {
    commit(SET_CURRENT_USER_IS_PARKED, data)
  },
  async performAction({ dispatch }, action) {
    await dispatch(`${CLASSIFIED_ACTIONS_NS}/performAction`, action, {
      root: true
    })
    if (this.$router.currentRoute?.name?.startsWith('__classifieds_view')) {
      dispatch('reloadClassifiedView')
    }
  },
  reloadClassifiedView({ dispatch }) {
    this.$cache.reset()
    dispatch('loadClassifiedView')
  },
  classifiedParkedStateChanged(
    { state, commit, rootState },
    isParked: boolean
  ) {
    commit(SET_CURRENT_USER_IS_PARKED, isParked)
    if (rootState.classifieds.search.rows) {
      commit(
        `${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_PARKED}`,
        { classifiedId: state.classified?.id, parked: isParked },
        { root: true }
      )
    }
  },
  classifiedTouchedStateChanged({ state, rootState, commit }, modified) {
    if (rootState.classifieds.search.rows) {
      commit(
        `${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_TOUCHED}`,
        { classifiedId: state.classified?.id, modified },
        { root: true }
      )
    }
  },
  async navigateToClassifiedView(
    { commit, rootGetters },
    {
      evt,
      url,
      setSp,
      query,
      refs,
      element,
      navigateInSearch,
      spParamName = 'sp'
    }: {
      url: string
      query: any
      evt: MouseEvent
      setSp: boolean
      refs: object
      element: HTMLElement
      navigateInSearch: boolean // use this when classified view is part/child of the search component
      spParamName: 'sp' | 'csp'
    }
  ) {
    if (evt) {
      evt.preventDefault()
      if (
        evt.altKey &&
        evt.metaKey &&
        evt.ctrlKey &&
        (process.env.NODE_ENV !== 'production' ||
          rootGetters[`${USER_NS}/isAdmin`])
      ) {
        query = { ...query, 'debug-shimmer': 1 }
      } else if (evt.ctrlKey) {
        window.open(url)
        return
      }
    }
    if (!element && document?.documentElement) {
      element = document.documentElement
    }
    // check for sp and history state manipulation due to a vue 2 router limitation
    if (setSp || navigateInSearch) {
      let replacedState = window.history.state
      if (navigateInSearch) {
        replacedState = { isInSearchPage: false, resetSearchState: true }
      }
      let replacedLocation = window.location.href
      if (setSp) {
        replacedLocation = setUrlParam(
          window.location.pathname + window.location.search,
          spParamName,
          element.scrollTop
        )
      }

      await window.history.replaceState(
        replacedState,
        document.title || '',
        replacedLocation
      )
    }

    commit(SET_REFS, refs)
    return this.$router.push({
      path: url,
      query
    })
  },

  async fetchExternalCertificates({ state, commit }, dt = {}) {
    try {
      const externalCertificatesService = this.$dep(
        ClassifiedExternalCertificatesService
      )
      const data = await externalCertificatesService.fetchExternalCertificates(
        dt.classifiedId || ((state.classifiedId as unknown) as number)
      )
      commit(SET_EXTERNAL_CERTIFICATE, data)
    } catch (error) {
      if ((error as any).response?.status === 404) {
        // @ts-ignore
        this.$snackbar.error(this.$i18n.t('no report found'))
      } else {
        this.$snackbar.error((error as any).message)
      }
    }
  },
  scrollToLeasingPartial() {
    const financePartial = document.getElementById('financePartial')
    if (financePartial) {
      financePartial.scrollIntoView({ behavior: 'smooth' })
    }
  },
  scrollToFinance({ getters }) {
    let elementToScrollTo
    const mapClassifiedView = document.getElementById('map-classified-view')
    if (getters.showFinance) {
      elementToScrollTo = document.getElementById('financePartial')
    } else if (getters.showLoanSection) {
      elementToScrollTo = document.getElementById('loanSection')
    }
    if (elementToScrollTo && !mapClassifiedView) {
      this.$dep(ScrollService).scrollTo(elementToScrollTo, { offset: -100 })
    } else if (mapClassifiedView && elementToScrollTo) {
      mapClassifiedView.scrollTo(
        0,
        elementToScrollTo.getBoundingClientRect().top - 100
      )
    }
  },
  scrollToInsurance() {
    const insuranceSection = document.getElementById('insurance-section')
    if (insuranceSection) {
      this.$dep(ScrollService).scrollTo(insuranceSection, { offset: -100 })
    }
  },
  setClassifiedNotes({ commit }, note: ClassifiedNote | null) {
    commit(SET_CLASSIFIED_NOTES, note)
  },
  async fetchOfferCount({ state, commit }, setOffers: boolean = true) {
    if (!state.classified?.id) {
      return
    }

    try {
      const classifiedOffersService = this.$dep(ClassifiedOffersService)
      const {
        offerCount,
        hasOfferForClassified,
        offer
      } = await classifiedOffersService.getOfferCountForClassified(
        state.classified.id
      )
      commit(SET_OFFER_COUNT, offerCount)
      commit(SET_HAS_OFFERS_FOR_CLASSIFIED, Boolean(hasOfferForClassified))

      if (setOffers) {
        commit(SET_CLASSIFIED_OFFERS, offer ? [offer] : [])
      }
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },
  async loadAuditRequests({ state, commit }) {
    const classifiedId = state.classified?.id

    if (!classifiedId) {
      return
    }

    const [auditRequestService] = this.$deps(AuditRequestService)

    try {
      const {
        auditRequests
      } = await auditRequestService.getAuditRequestsForClassified(classifiedId)

      // if there is a self-audit request then bring it on top
      for (let i = 0; i < auditRequests.length; i++) {
        if (auditRequests[i].requestedByUser.id === state.seller?.id) {
          const [selfAudit] = auditRequests.splice(i, 1)

          auditRequests.unshift(selfAudit)
          break
        }
      }

      commit(SET_AUDIT_REQUESTS, auditRequests)
    } catch (err) {}
  },
  async loadAuditsInfo({ dispatch, state, commit }) {
    const classifiedId = state.classified?.id

    if (!classifiedId) {
      return
    }

    const [classifiedService] = this.$deps(ClassifiedService)

    try {
      const { audits } = await classifiedService.getAuditsInfo(classifiedId)

      commit(SET_AUDITS, audits)

      if (audits?.userHasAuditRequests) {
        dispatch('loadAuditRequests')
      }
    } catch (error) {}
  },
  async setClassifiedOfferState(
    _,
    {
      offerId,
      state,
      successCallback
    }: {
      offerId: number
      state: OfferState
      successCallback: Function
    }
  ) {
    try {
      const classifiedOffersService = this.$dep(ClassifiedOffersService)
      const { offer } = await classifiedOffersService.setOfferState(
        offerId,
        state
      )
      let successMessage: string = ''
      switch (state) {
        case OfferState.WITHDRAWN:
          // @ts-ignore
          successMessage = this.$i18n.t(
            'offer cancelled successfully'
          ) as string
          break
        case OfferState.ACCEPTED:
          // @ts-ignore
          successMessage = this.$i18n.t('offer accepted successfully') as string
          break
        case OfferState.REJECTED:
          // @ts-ignore
          successMessage = this.$i18n.t('offer rejected successfully') as string
          break
      }
      this.$snackbar.success(successMessage)
      await successCallback(offer)
    } catch (e) {
      handleError(e as AxiosError, this.$snackbar, this.$logger, this.app.$t)
    }
  },

  setContactFormData({ commit }, formData: ContactFormData) {
    commit(SET_CONTACT_FORM_DATA, formData)
  }
} as ActionTreeWithRootState<ClassifiedViewState>
