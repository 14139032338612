














































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import { useI18n } from '~/compositions/i18n'
import { GeolocationService } from '~/services/navigator/GeolocationService'
import { useDeps } from '~/compositions/dependency-container'
import GoogleMapsService from '~/services/GoogleMapsService'
import { GoogleAutocompleteInputMode } from '~/models/google'
import { preventNonNumericKeyboardEvent } from '~/utils/dom'
import { InputSize } from '~/models/app/input'
import { Geolocation } from '~/models/common/types'
import { geolocationToGoogleGeolocation } from '~/utils/geolocation'
import { CountryCode } from '~/models/location/country'
import { useSnackbar } from '~/compositions/snackbar'
import { ciLocationPin } from '~/icons/source/solid/location-pin'
import { ciTimes } from '~/icons/source/solid/times'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    gps: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    countryCodes: {
      type: Array as PropType<string[]>,
      default() {
        return ['gr', 'cy', 'bg', 'al', 'tr', 'mk']
      }
    },
    isValid: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String as PropType<GoogleAutocompleteInputMode>,
      default: GoogleAutocompleteInputMode.ADDRESS
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const snackbar = useSnackbar()
    const [geolocationService, googleMapsService] = useDeps(
      GeolocationService,
      GoogleMapsService
    )

    const loading = ref<boolean>(false)
    const googleAutocompleteTemplateRef = ref<HTMLInputElement | null>(null)
    const inputKeydownListener = ref<any>(null)
    const internalValue = ref(props.modelValue)
    const inputFocused = ref(false)
    const autocompleteInput = ref<google.maps.places.Autocomplete | null>(null)
    const placeChangeListener = ref<google.maps.MapsEventListener | null>(null)

    onMounted(async () => {
      if (!autoCompleteInput.value) {
        return
      }

      autocompleteInput.value = await googleMapsService.createAutocompleteInput(
        autoCompleteInput.value,
        props.countryCodes
      )

      placeChangeListener.value = await googleMapsService.addListener(
        autocompleteInput.value,
        'place_changed',
        async () => {
          if (!autocompleteInput.value) {
            return
          }
          const place = await getPlace()
          if (!place.geometry?.location) {
            return snackbar.error(t('location not found'))
          }
          const geolocation: Geolocation = {
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
          }
          const postcode = await getPostcode(place, geolocation)
          const city = googleMapsService.extractCity([place])
          const address = place.formatted_address
          const country = googleMapsService.extractCountry([
            place
          ]) as CountryCode

          switch (props.mode) {
            case GoogleAutocompleteInputMode.POSTCODE: {
              internalValue.value = postcode
              break
            }
            case GoogleAutocompleteInputMode.CITY: {
              internalValue.value = city
              break
            }
            case GoogleAutocompleteInputMode.COUNTRY: {
              internalValue.value = country
              break
            }
            case GoogleAutocompleteInputMode.ADDRESS:
            default: {
              internalValue.value = address
              break
            }
          }
          emit('change', { postcode, city, country, address, geolocation })
        }
      )

      inputKeydownListener.value = autoCompleteInput.value.addEventListener(
        'keydown',
        (e: any) => {
          switch (props.mode) {
            case GoogleAutocompleteInputMode.POSTCODE: {
              // prevent non-numeric input text when the mode is postcode
              preventNonNumericKeyboardEvent(e)
            }
          }
        }
      )
    })

    watch(
      () => props.modelValue,
      newValue => {
        internalValue.value = newValue
      }
    )

    onBeforeUnmount(() => {
      if (autoCompleteInput.value) {
        autoCompleteInput.value.removeEventListener(
          'keydown',
          inputKeydownListener.value
        )
      }

      if (placeChangeListener.value) {
        googleMapsService.removeListener(placeChangeListener.value)
      }
    })

    const autoCompleteInput = computed(
      () => googleAutocompleteTemplateRef.value?.inputTemplateRef
    )

    async function getCurrentGpsLocation() {
      if (props.disabled) {
        return
      }

      try {
        const gpsGeolocation = await geolocationService.getCurrentGpsLocation()

        if (!gpsGeolocation) {
          return
        }

        const {
          city,
          address,
          postcode
        } = await googleMapsService.getGeocodingProperties(gpsGeolocation)

        internalValue.value = address

        emit('change', { city, address, postcode, geolocation: gpsGeolocation })
      } catch (err) {
        snackbar.error(t('failed to get gps location'))
      }
    }

    function clear() {
      if (props.disabled) {
        return
      }

      internalValue.value = null

      emit('change', {
        city: null,
        address: null,
        postcode: null,
        geolocation: null
      })
    }

    function onInputFocusIn() {
      inputFocused.value = true
    }

    function onInputFocusOut() {
      inputFocused.value = false
    }

    function onInputInput(value: string) {
      emit('update:modelValue', value)
    }

    async function getPostcode(
      place: any,
      geolocation: Geolocation
    ): Promise<string | null> {
      const postcode = googleMapsService.extractPostalCode([place])
      if (postcode) {
        return postcode
      }
      // attempt to infer the postalcode from geolocation
      const geocoderResults = await googleMapsService.geocode({
        location: geolocationToGoogleGeolocation(geolocation)
      })
      return (
        (geocoderResults.length > 0 &&
          googleMapsService.extractPostalCode(geocoderResults)) ||
        null
      )
    }

    async function getPlace() {
      let place = autocompleteInput.value!.getPlace()
      if (place?.geometry?.location) {
        return place
      }
      const nameGeocodeResults: google.maps.GeocoderResult[] = await googleMapsService.geocode(
        { address: place.name }
      )
      if (nameGeocodeResults?.length > 0) {
        place = { name: place.name, ...nameGeocodeResults[0] }
      }
      return place
    }

    return {
      ciLocationPin,
      ciTimes,
      loading,
      googleAutocompleteTemplateRef,
      internalValue,
      inputFocused,
      getCurrentGpsLocation,
      clear,
      onInputFocusIn,
      onInputFocusOut,
      onInputInput
    }
  }
})
