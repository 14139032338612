import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__user_verify',
    path: '/user/verify/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/users/verify.vue' /* webpackChunkName: "pages-users-verify.vue" */
        )
      )
  },
  {
    name: '__user_verify_faq',
    path: '/user/verify/faq',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/information/faq/verify-user-faq.vue' /* webpackChunkName: "pages-information-faq-verify-user-faq.vue" */
        )
      )
  }
]
