var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tw-inline-flex"},[_c('div',{staticClass:"tw-cursor-pointer tw-text-center tw-inline-flex focus-visible:tw-border focus-visible:tw-border-b-solid focus-visible:tw-border-blue",class:[
      _vm.forceBlue
        ? 'tw-text-blue-700'
        : 'tw-text-grey-400 hover:tw-text-blue-700 ',
      _vm.buttonClass
    ],attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showModal = true}}},[_vm._t("beforeIcon"),_vm._v(" "),_c('CIcon',{ref:"infocircle",staticClass:"tw-relative tw-text-xl",attrs:{"icon":_vm.iconToShow,"size":_vm.size}})],2),_vm._v(" "),_c('CModal',{attrs:{"centered":"","title":_vm.title || _vm.$t('information'),"size":_vm.modalSize,"hide-footer":!_vm.showFooter,"body-class":_vm.bodyClass,"return-focus":"#__nuxt"},on:{"ok":function($event){return _vm.$emit('ok')},"close":function($event){return _vm.$emit('cancel')},"cancel":function($event){return _vm.$emit('cancel')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
    var ok = ref.ok;
return [_c('CButton',{staticClass:"confirm-button",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v("\n        "+_vm._s(_vm.okText)+"\n      ")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }