import VueLib from 'vue'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  const opts = Object.assign({}, {"dsn":"https:\u002F\u002Fc2f7280d44164a0b86708224e599d5f5@mnsentry.car.gr\u002F8","environment":"production","denyUrls":[new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("^https*:\\\u002F\\\u002Fcdn.ampproject.org\\\u002F", "i"),new RegExp("^https*:\\\u002F\\\u002Fraw.githubusercontent.com\\\u002F", "i")],"beforeSend":(event, hint) => {
      class ExceptionCapturingInterceptor {
        /**
         * @param {ErrorIdentifier} errorIdentifier
         */
        constructor(errorIdentifier) {
          this.errorIdentifier = errorIdentifier
        }

        /**
         *
         * @param {Event} event
         * @param {EventHint} hint
         * @returns {Promise<null|Event>}
         */
        async beforeOutgoingEventToSentry(event, hint) {
          if (hint && !(await this.shouldBeCaptured(hint))) {
            return null
          }
          return event
        }

        /**
         *
         * @param {EventHint} hint
         * @returns {Promise<boolean>}
         */
        async shouldBeCaptured(hint) {
          if (hint && typeof hint.originalException === 'string') {
            return true
          }

          const { errorIdentifier } = this
          const error = await errorIdentifier.getError(hint.originalException)

          return (
            !(await errorIdentifier.isPerformanceDegradationHttpError(error)) &&
            !(await errorIdentifier.isUnauthorizedHttpError(error))
          )
        }
      }

      class ErrorIdentifier {
        constructor() {
          this.errorStatusCodes = {
            PERFORMANCE_DEGRADATION: 476,
            UNAUTHORIZED: 401
          }
        }

        /**
         *
         * @param {Error|Promise<Error>} error
         * @returns {Promise<boolean>}
         */
        async isPerformanceDegradationHttpError(error) {
          const err = await this.getError(error)
          const { isAxiosError } = err
          const status = await this.getStatus(err)
          return (
            isAxiosError &&
            status === this.errorStatusCodes.PERFORMANCE_DEGRADATION
          )
        }

        /**
         *
         * @param {Error|Promise<Error>} error
         * @returns {Promise<boolean>}
         */
        async isUnauthorizedHttpError(error) {
          const err = await this.getError(error)
          const { isAxiosError } = err
          const status = await this.getStatus(err)
          return isAxiosError && status === this.errorStatusCodes.UNAUTHORIZED
        }

        async getStatus(error) {
          const err = await this.getError(error)

          return err && err.response && err.response.status
        }

        /**
         * @param {Error} error
         * @returns {Error|Promise<Error>}
         */
        async getError(error) {
          if (error instanceof Promise) {
            try {
              const e = await error
              return e
            } catch (e) {
              return e
            }
          }
          return error
        }
      }

      /**
       * @returns {ExceptionCapturingInterceptor}
       */
      function exceptionCapturingInterceptor() {
        return new ExceptionCapturingInterceptor(new ErrorIdentifier())
      }

      const interceptor = exceptionCapturingInterceptor()
      return interceptor.beforeOutgoingEventToSentry(event, hint)
    },"sampleRate":0.1}, {
    integrations: [
      new Dedupe({}),
      new ExtraErrorData({}),
      new ReportingObserver({}),
      new RewriteFrames({}),
      new Vue({Vue: VueLib, ...{"attachProps":true}})
    ]
  })

  // Initialize sentry
  Sentry.init(opts)

  // Inject Sentry to the context as $sentry
  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
