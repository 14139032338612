import { Inject, Context } from '@nuxt/types/app'
import AnalyticsService from '~/services/AnalyticsService'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import LoggerService from '~/services/LoggerService'
import SnackbarService from '~/services/snackbar/SnackbarService'

export default (
  { app: { $requestContainer, router } }: Context,
  inject: Inject
) => {
  const globalDeps = {
    cache: $requestContainer.resolve(CacheService),
    logger: $requestContainer.resolve(LoggerService),
    cookies: $requestContainer.resolve(CookiesService),
    analytics: $requestContainer.resolve(AnalyticsService),
    snackbar: $requestContainer.resolve(SnackbarService)
  }

  for (const [key, value] of Object.entries(globalDeps)) {
    if (typeof value !== 'undefined') {
      inject(key, value)
    }
  }

  inject('router', router)
}
