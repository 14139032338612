














import SearchableKeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/SearchableKeyValueSearchHandler.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import SellersResultsToggle from '~/components/car/classifieds/search/results/SellersResultsToggle.vue'
import { mapGetters } from 'vuex'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LegacySearchService from '~/services/search/LegacySearchService'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import SearchConfigService from '~/services/search/SearchConfigService'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { SearchableKeyValueSearchHandler, SellersResultsToggle },
  extends: BaseSearchHandler,
  props: {
    handler: { type: Object, required: true },
    value: { type: Object, default: null }
  },
  computed: {
    ...mapDeps({
      legacySearchService: LegacySearchService,
      searchConfigService: SearchConfigService
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    isSellersResultsToggleVisible() {
      const isFiltersPage = this.searchConfigService.getConfig(this.$route)
        ?.settings?.isFiltersPage
      return !isFiltersPage
    }
  }
})
