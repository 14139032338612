
import { mapActions, mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { facetChanged } from '~/store/modules/shared/classifieds/search/action-types'
import { CategoryId } from '~/models/category/types'
import { Facet } from '~/models/search/types'
import { Param } from '~/models/common/types'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    humanName: {
      type: String,
      required: true
    },
    isImportant: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    searchables: {
      type: [Array, Object],
      default() {
        return []
      }
    },
    humanParams: {
      type: Array,
      default() {
        return []
      }
    },
    params: {
      type: Array,
      default() {
        return []
      }
    },
    selected: {
      type: [Array, Object],
      default: null
    },
    // this should be in a different object inside values as extras but passed here for now
    allValues: {
      type: Object,
      required: true
    },
    strictValues: {
      type: Boolean,
      default: false
    },
    urlArgNames: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isMultiselect: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, Boolean, String, Number],
      default: null
    },
    defaultCategory: {
      type: Number,
      default: CategoryId.CLASSIFIEDS
    },
    handler: {
      type: Object as PropType<Facet>,
      required: true
    },
    searchPlaceholder: {
      type: String,
      default: null
    },
    // this should not be top-level probably
    variant: {
      type: Object,
      default() {
        return {}
      }
    },
    isNested: {
      type: Boolean,
      default: false
    },
    noPortal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    defaultUrlArgName() {
      return this.urlArgNames[0]
    },
    ...mapState(CLASSIFIED_SEARCH_NS, {
      searchParams: state => state.params
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName',
      facetIsSelected: 'facetIsSelected'
    })
  },
  watch: {
    allValues: {
      immediate: true,
      handler(newValues) {
        this.onBackendValues(newValues)
      }
    }
  },
  beforeMount() {
    this.unsubscribeFromStore = this.$store.subscribeAction(
      (action, _state) => {
        const { type, payload } = action
        switch (type) {
          case 'classifieds/search/clearFacet': {
            if (this.isNested) {
              break
            }
            if (this.refersToCurrentHandler(payload.name)) {
              this.onClear()
            }
            break
          }
          case 'classifieds/search/clearAll': {
            this.onClearAll()
          }
        }
      }
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore()
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      facetChanged
    }),
    refersToCurrentHandler(handlerName: string) {
      return this.name === handlerName
    },
    emitParams(params: Param[] = []) {
      this.facetChanged({
        params
      })
    },
    // these methods can be overridden by each handler
    onClear() {},
    onClearAll() {},
    onBackendValues(values: any) {
      this.selectedValue = values && values.selected
    }
  }
})
