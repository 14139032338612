




























import { mapActions, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CTreeSelect from '~/components/shared/configurable/form/select/tree/CTreeSelect.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import ExpandableHandlerContent from '~/components/car/classifieds/search/facets/handlers/ExpandableHandlerContent.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CTreeSelect,
    ExpandableHandlerContent
  },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    isExpandable() {
      const { isSelected, searchables } = this
      return !isSelected && searchables && searchables.length > 11
    },
    ...mapState(CLASSIFIED_SEARCH_NS, {
      rootCategory: state => state.config.rootParams.category
    })
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch'
    }),
    onClear() {
      if (this.name === 'category') {
        this.emitParams([
          { name: this.defaultUrlArgName, value: [this.rootCategory] }
        ])
        return
      }
      this.selectedValues = []
      this.emitParams([
        { name: this.defaultUrlArgName, value: this.selectedValues }
      ])
    },
    onBackendValues(values: any) {
      this.selectedValues = values.selected
    },
    handleRedirectUrlClick(redirectUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl: redirectUrl
      })
    },
    treeChanged(newValues: any[]) {
      this.selectedValues = newValues
      this.emitParams([{ name: this.defaultUrlArgName, value: newValues }])
    }
  }
})
