import { Context } from '@nuxt/types'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { USER_NS } from '~/store/modules/shared/user/state'

// Used universally when should-login param is given
export default function({
  $dep,
  route,
  redirect,
  app,
  store,
  $config
}: Context) {
  const legacyUrlService = $dep(LegacyUrlService)
  const { router } = app

  const userIsAnon = store.getters[`${USER_NS}/isAnon`]

  if (route.query['should-login'] && userIsAnon) {
    const baseUrl = process.client
      ? window.location.origin
      : `https://${$config.public.domain || 'www.car.gr'}`

    const urlObj = new URL(baseUrl + route.fullPath)
    urlObj.searchParams.delete('should-login')
    const gotonext = urlObj.pathname + urlObj.search

    const resolvedRoute = router?.resolve({
      name: '__login',
      query: { gotonext }
    })

    // if login route name exists in the app's nuxt router (ie plot) then use that else go to the legacy urls
    const redirectUrl =
      resolvedRoute?.resolved?.matched?.length && resolvedRoute?.href
        ? resolvedRoute.href
        : `${legacyUrlService.getUserLoginUrl()}?gotonext=${route.path}`
    return redirect(redirectUrl)
  }
}
