import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__classifieds_edit',
    path: '/classifieds/:classifiedId/edit/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/edit/edit.vue' /* webpackChunkName: "pages-classifieds-edit-edit" */
        )
      ),
    redirect: { name: '__classifieds_edit_details' },
    children: [
      // default route when the user navigates to /:categoryName/edit/:classifiedId
      {
        path: 'details/',
        name: '__classifieds_edit_details',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/details.vue' /* webpackChunkName: "pages-classifieds-edit-details" */
            )
          ),
        pathToRegexpOptions: { strict: true },
        meta: {
          showOneTap: true
        }
      },
      {
        path: 'charges/',
        name: '__classifieds_edit_charges',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/charges.vue' /* webpackChunkName: "pages-classifieds-edit-charges" */
            )
          )
      },
      {
        path: 'certificates/',
        name: '__classifieds_edit_certificates',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/certificates.vue' /* webpackChunkName: "pages-classifieds-edit-certificates" */
            )
          )
      },
      {
        path: 'statistics/',
        name: '__classifieds_edit_statistics',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/statistics.vue' /* webpackChunkName: "pages-classifieds-edit-statistics" */
            )
          )
      },
      {
        path: 'messages/',
        name: '__classifieds_edit_messages',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/messages.vue' /* webpackChunkName: "pages-classifieds-edit-messages" */
            )
          )
      },
      {
        path: 'eshop/',
        name: '__classifieds_edit_eshop',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/classifieds/edit/eshop.vue' /* webpackChunkName: "pages-classifieds-edit-eshop" */
            )
          )
      },
      {
        path: 'trades/',
        name: '__classifieds_edit_trades',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/classifieds/edit/trades.vue' /* webpackChunkName: "pages-classifieds-edit-trades" */
            )
          )
      }
    ]
  }
]
