




















import {
  computed,
  defineComponent,
  nextTick,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  PropOptions,
  PropType,
  ref,
  toRefs,
  useStore
} from '~/utils/nuxt3-migration'
import CInHouseEntry from '~/components/shared/configurable/in-house/CInHouseEntry.vue'
import CGoogleAd from '~/components/shared/configurable/ad/CGoogleAd.vue'
import { GoogleAd } from '~/models/ads'
import { KeyValue } from '~/models/common/types'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { getRandomNumberBetween } from '~/utils/number'
import { CategoryId } from '~/models/category/types'
import { useNamespacedStore } from '~/compositions/store'
import {
  CLASSIFIED_VIEW_NS,
  ClassifiedViewState
} from '~/store/modules/shared/classifieds/view/state'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'

export default defineComponent({
  components: { CGoogleAd, CInHouseEntry },
  props: {
    unit: {
      type: String,
      required: false
    },
    ad: {
      type: Object as PropType<GoogleAd>,
      required: true
    },
    keyValues: {
      type: Array,
      required: false,
      default() {
        return []
      }
    } as PropOptions<KeyValue[]>,
    placeholder: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    },
    divCollapse: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showInHouse = ref(true)
    const gRef = ref(null)
    const iRef = ref(null)
    const { unit } = toRefs(props)

    const disableInHouse = ref(true)

    const { state: searchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const { state: userState } = useNamespacedStore<UserState>(USER_NS)

    const { state: classifiedViewState } = useNamespacedStore<
      ClassifiedViewState
    >(CLASSIFIED_VIEW_NS)

    const categories = computed(() => {
      if (!unit.value) {
        return []
      }

      if (unit.value.startsWith('in_house_search')) {
        if (searchState.categoryIds?.length) {
          return searchState.categoryIds.filter(
            c => c !== CategoryId.CLASSIFIEDS // no need to send this one
          )
        }
      } else if (unit.value.startsWith('in_house_cls')) {
        // in classified view
        return classifiedViewState.classified?.category_ids || []
      }

      return []
    })

    const googleChance = computed(() => {
      if (userState.inHouseConfig?.length) {
        const foundConfig = userState.inHouseConfig.find(
          c => categories.value?.includes(c.category) || c.category === 20001
        )
        if (foundConfig) {
          return 100 - foundConfig.inHousePercentage
        }
      }
      return categories.value?.includes(CategoryId.CARS) ? 95 : 80 // just in case
    })

    const rollTheDice = () => {
      disableInHouse.value = getRandomNumberBetween(1, 100) < googleChance.value
    }
    onBeforeMount(() => {
      rollTheDice()
    })
    const store = useStore()

    const refreshInHouse = () => {
      rollTheDice()

      if (!disableInHouse.value) {
        if (!showInHouse.value) {
          showInHouse.value = true
        } else if (iRef.value) {
          iRef.value.refresh()
        }
      }
    }
    const unsubscribe = store.subscribeAction(action => {
      if (action.type === CLASSIFIED_SEARCH_NS + '/refreshAds') {
        refreshInHouse()
      }
    })

    onActivated(() => {
      refreshInHouse()
    })

    onBeforeUnmount(() => {
      unsubscribe()
    })

    const onNoFetch = async () => {
      showInHouse.value = false
      await nextTick()
      // if (gRef.value) {
      //   gRef.value.refresh()
      // }
    }
    return {
      showInHouse,
      onNoFetch,
      refreshInHouse,
      gRef,
      iRef,
      googleChance,
      disableInHouse
    }
  }
})
