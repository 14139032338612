import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import RouteGuardService from '~/services/RouteGuardService'
import { ClientFlashMessageType } from '~/models/flash/types'
import FlashService from '~/services/flash/FlashService'

export default function({ $dep, query, redirect, $i18n }: any) {
  const routeGuardService = $dep(RouteGuardService)
  if (routeGuardService.userIsLoggedIn()) {
    if (query.gotonext && !query.gotonext.includes('gotonext')) {
      return redirect(query.gotonext)
    }

    const flashService = $dep(FlashService)
    flashService.redirectWithFlash(
      '/',
      ClientFlashMessageType.ALREADY_LOGGED_IN,
      {
        showAsSnackbar: true,
        snackbarVariant: 'error',
        text: $i18n.t('you are already logged in')
      }
    )
  }
}
