import { render, staticRenderFns } from "./CPosting.vue?vue&type=template&id=59a70797&scoped=true&"
import script from "./CPosting.vue?vue&type=script&lang=ts&"
export * from "./CPosting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a70797",
  null
  
)

export default component.exports