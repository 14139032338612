import { CIcon } from '~/icons/types'

export const ciExclamationCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6ZM12 18c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2S12.7 18 12 18Zm1.2-6c0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2V7.2c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2V12Z',
      fill: 'currentColor'
    },
    {
      d:
        'M12 18a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Zm0-12c-.7 0-1.2.5-1.2 1.2V12c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2V7.2c0-.7-.5-1.2-1.2-1.2Z',
      fill: '#fff'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'exclamation-circle',
  type: 'solid'
}
