



































import {
  computed,
  defineComponent,
  PropType,
  toRefs,
  useRoute
} from '~/utils/nuxt3-migration'
import CJsonld from '~/components/shared/configurable/seo/CJsonld.vue'
import { Breadcrumb } from '~/models/common/types'
import { getRouteMetaItem } from '~/utils/router'
import { ciHome } from '~/icons/source/regular/home'
import { ciAngleRight } from '~/icons/source/regular/angle-right'

export default defineComponent({
  components: {
    CJsonld
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Breadcrumb[]>,
      default: () => {
        return []
      }
    },
    nuxtLinks: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute()

    const { breadcrumbs } = toRefs(props)

    const showBreadcrumbs = computed(
      () => !getRouteMetaItem(route.value, 'noBreadcrumbs')
    )
    const jsonld = computed(() => ({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.value.map(
        (breadcrumb: Breadcrumb, index: number) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.text,
            item: `https://car.gr${breadcrumb.url}`
          }
        }
      )
    }))

    return {
      ciHome,
      ciAngleRight,
      showBreadcrumbs,
      jsonld
    }
  }
})
