

















import { defineComponent } from '~/utils/nuxt3-migration'

interface Data {
  expanded: boolean
}

export default defineComponent({
  props: {
    isExpandable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): Data {
    return {
      expanded: !this.isExpandable
    }
  },
  watch: {
    isExpandable(newExpandableValue) {
      if (newExpandableValue && this.$refs.content) {
        this.$refs.content.scrollTop = 0
      }
      this.expanded = !newExpandableValue
    }
  },
  methods: {
    expand() {
      this.expanded = true
    }
  }
})
