import { CIcon } from '~/icons/types'

export const ciTimes: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M4.94 4.94a1.5 1.5 0 0 1 2.12 0L12 9.878l4.94-4.94a1.5 1.5 0 0 1 2.12 2.122L14.122 12l4.94 4.94a1.5 1.5 0 0 1-2.122 2.12L12 14.122l-4.94 4.94a1.5 1.5 0 0 1-2.12-2.122L9.878 12l-4.94-4.94a1.5 1.5 0 0 1 0-2.12Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'times',
  type: 'solid'
}
