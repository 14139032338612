import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__landing_pages_rentals',
    path: '/landing/rentals/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/rentals/index.vue' /* webpackChunkName: "landing-pages-rentals.vue" */
        )
      )
  },
  {
    name: '__landing_pages_promotions',
    path: '/landing/promotions/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/promotions/index.vue' /* webpackChunkName: "landing-pages-promotions.vue" */
        )
      )
  },
  {
    name: '__landing_pages_finance',
    path: '/landing/finance/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/finance/index.vue' /* webpackChunkName: "landing-pages-finance.vue" */
        )
      )
  },
  {
    name: '__landing_pages_tbi',
    path: '/landing/tbi/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/finance/tbi.vue' /* webpackChunkName: "landing-pages-tbi.vue" */
        )
      )
  },
  {
    name: '__landing_pages_tbi_blackfriday',
    path: '/landing/tbi-blackfriday/',
    meta: { isLanding: true, blackFriday: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/finance/tbi.vue' /* webpackChunkName: "landing-pages-tbi.vue" */
        )
      )
  },
  {
    name: '__landing_pages_audits',
    path: '/landing/audits/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/audits/index.vue' /* webpackChunkName: "landing-pages-audits.vue" */
        )
      )
  },
  {
    name: '__landing_pages_wanted_parts',
    path: '/landing/wanted-parts/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/wanted-parts/index.vue' /* webpackChunkName: "landing-pages-wanted-parts.vue" */
        )
      )
  },
  {
    name: '__landing_pages_marketplace_sellers',
    path: '/landing/marketplace-sellers/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/marketplace-sellers.vue' /* webpackChunkName: "landing-pages-marketplace-sellers.vue" */
        )
      )
  },
  {
    name: '__landing_pages_marketplace',
    path: '/landing/marketplace/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/marketplace.vue' /* webpackChunkName: "landing-pages-marketplace.vue" */
        )
      )
  }
]
