import { CIcon } from '~/icons/types'

export const ciCheck: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M20.332 4.002a1.5 1.5 0 0 1 .416 2.08l-9 13.5a1.5 1.5 0 0 1-2.309.229l-6-6a1.5 1.5 0 1 1 2.122-2.122l4.706 4.706 7.985-11.977a1.5 1.5 0 0 1 2.08-.416Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'check',
  type: 'solid'
}
