import { adminBlacklistRoutes } from '~/router/routes/car/admin/blacklist'
import { adminCategoriesRoutes } from '~/router/routes/car/admin/categories'
import { adminPopularityRoutes } from '~/router/routes/car/admin/popularity'
import { adminSearchRoutes } from '~/router/routes/car/admin/search'
import { adminSearchLogsRoutes } from '~/router/routes/car/admin/logs'
import { adminMissingMakeModelsRoutes } from './missing-makemodels'
import { adminAdvertisingRoutes } from '~/router/routes/car/admin/advertising'
import { adminWorkStatsRoute } from '~/router/routes/car/admin/work-stats'
import { adminSaleRequestsRoutes } from '~/router/routes/car/admin/sale-requests'
import { adminMarketplaceRoutes } from '~/router/routes/car/admin/marketplace'

export default [
  ...adminBlacklistRoutes,
  ...adminCategoriesRoutes,
  ...adminAdvertisingRoutes,
  ...adminPopularityRoutes,
  ...adminSearchRoutes,
  ...adminSearchLogsRoutes,
  ...adminMissingMakeModelsRoutes,
  ...adminWorkStatsRoute,
  ...adminSaleRequestsRoutes,
  ...adminMarketplaceRoutes
]
