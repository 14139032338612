





































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  PropType,
  ref,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: { type: Boolean, default: false },
    duration: {
      type: Object as PropType<Record<'show' | 'hide', number>>,
      default: () => ({ show: 350, hide: 350 })
    },
    contentClass: { type: [Array, String, Object], default: () => [] },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const collapseContentTemplateRef = ref<HTMLElement | null>()
    const isExpanded = ref(props.modelValue)
    const maxHeight = ref(isExpanded.value ? 'initial' : '0')
    let maxHeightTimeout
    const overflowClass = ref('tw-overflow-hidden')

    watch(
      () => props.modelValue,
      (newVal: boolean) => {
        isExpanded.value = newVal
      }
    )

    watch(isExpanded, newExpandedValue => setMaxHeight(newExpandedValue))

    const contentClasses = computed(() => {
      return [...props.contentClass]
    })

    function getWrapperHeight() {
      if (!collapseContentTemplateRef.value) {
        return null
      }
      return collapseContentTemplateRef.value.scrollHeight
    }

    function handleOpenerClick() {
      isExpanded.value = !isExpanded.value

      emit('update:modelValue', isExpanded.value)
    }

    function expand() {
      isExpanded.value = true
    }

    async function setMaxHeight(expanded: boolean) {
      maxHeight.value = `${getWrapperHeight()}px`
      await nextTick()
      if (maxHeightTimeout) {
        clearTimeout(maxHeightTimeout)
      }
      if (expanded) {
        maxHeightTimeout = setTimeout(() => {
          maxHeight.value = 'initial'
          overflowClass.value = ''
        }, props.duration.show + 50)
      } else {
        setTimeout(() => {
          overflowClass.value = 'tw-overflow-hidden'
          maxHeight.value = '0'
        }, 50)
      }
    }

    onBeforeUnmount(() => {
      if (maxHeightTimeout) {
        clearTimeout(maxHeightTimeout)
      }
    })

    return {
      expand,
      isExpanded,
      handleOpenerClick,
      collapseContentTemplateRef,
      maxHeight,
      contentClasses,
      overflowClass
    }
  }
})
