import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import CookiesService from '~/services/CookiesService'
import { CONSENT_COOKIE_NAME } from '~/constants/cookies'

@ClientOnly
@containerScoped()
export default class GoogleAdsService {
  constructor(@inject(CookiesService) private cookies: CookiesService) {}

  initialize() {
    window.googletag = window.googletag || {}
    window.googletag.cmd = window.googletag.cmd || []
  }

  disableInitialLoad() {
    this.enqueueCommand(() => {
      const pubAds = this.getPubAds()
      if (!pubAds) {
        return
      }
      pubAds.disableInitialLoad()
    })
  }

  getPubAds() {
    const gTag = this.getGoogleTag()
    return gTag && gTag.pubads && gTag.pubads()
  }

  getGoogleTag() {
    return window.googletag
  }

  enqueueCommand(command: () => any) {
    const gTag = this.getGoogleTag()
    if (!gTag) {
      return
    }
    gTag.cmd.push(command)
  }

  getSlotByAdId(id: string) {
    const pubAds = this.getPubAds()
    if (!pubAds) {
      return
    }
    const allSlots = pubAds.getSlots()
    for (const slot of allSlots) {
      if (slot.getSlotElementId() === id) {
        return slot
      }
    }
  }

  setCollapseEmptyDivs() {
    this.enqueueCommand(() => {
      const pubAds = this.getPubAds()
      if (!pubAds) {
        return
      }
      pubAds.collapseEmptyDivs()
    })
  }

  destroySlots(slots: any) {
    if (!slots || slots.length === 0) {
      return
    }
    this.enqueueCommand(() => {
      const gTag = this.getGoogleTag()
      if (!gTag) {
        return
      }
      gTag.destroySlots(slots)
    })
  }

  refreshSlots(slots: any[]) {
    if (!slots) {
      return
    }
    this.enqueueCommand(() => {
      const pubAds = this.getPubAds()
      if (!pubAds) {
        return
      }
      pubAds.refresh(slots)
    })
  }

  refreshAllSlots() {
    this.enqueueCommand(() => {
      const pubAds = this.getPubAds()
      if (!pubAds || pubAds.getSlots().length === 0) {
        return
      }
      pubAds.refresh()
    })
  }

  defineSlot(path: string, sizes: any[], id: string) {
    const gTag = this.getGoogleTag()
    if (!gTag) {
      return
    }
    return gTag.defineSlot(path, sizes, id)
  }

  defineSlotSizeMapping(slot: any, sizesMappings: any[]) {
    const gTag = this.getGoogleTag()
    if (!gTag) {
      return
    }
    const map = gTag.sizeMapping()
    for (const mapping of sizesMappings) {
      map.addSize(mapping[0], mapping[1])
    }
    const builtMap = map.build()
    slot.defineSizeMapping(builtMap)
  }

  setTargeting(slot: any, keyValues: any[]) {
    for (const keyValue of keyValues) {
      const { key, value } = keyValue
      slot.setTargeting(key, value)
    }
  }

  prepareAndDisplaySlot(slot: any, _id: string) {
    const pubAds = this.getPubAds()
    const gTag = this.getGoogleTag()
    if (!(gTag && pubAds)) {
      return
    }

    pubAds.enableLazyLoad({
      // Fetch slots within 200vh.
      fetchMarginPercent: 200,
      // Render slots within 80vh.
      renderMarginPercent: 80,
      // Double the above values on mobile, where viewports are smaller
      // and users tend to scroll faster.
      mobileScaling: 3.0
    })

    slot.addService(pubAds)

    if (!gTag.pubadsReady) {
      gTag.enableServices()
    }

    gTag.display(slot)

    const choiceMade = this.cookies.get(CONSENT_COOKIE_NAME)
    if (choiceMade) {
      this.refreshSlots([slot])
    }
  }
}
